import React from 'react';
import StatusMessage from 'components/common/StatusMessage';
import ErrorBoundary from 'components/common/ErrorBoundary';

const Loading = ({ loadingMessage = 'Loading...', children }) => {
  return (
    <React.Suspense
      fallback={
        <StatusMessage icon="fa-spinner fa-spin">
          {loadingMessage}
        </StatusMessage>
      }
    >
      <ErrorBoundary>{children}</ErrorBoundary>
    </React.Suspense>
  );
};

export default Loading;
