import React from 'react';

const Insights = ({ stroke = '#343434' }) => (
  <svg width="22px" height="23px" viewBox="0 0 22 23">
    <g
      id="----Know-How"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Know-How-Doc-Type-Icons"
        transform="translate(-1.000000, -81.000000)"
        stroke={stroke}
      >
        <g id="Group-28" transform="translate(1.000000, 0.000000)">
          <g id="icon-insights" transform="translate(1.000000, 82.000000)">
            <path
              d="M16,10 C16,6.6865 13.3125,4 10,4 C6.6855,4 4,6.6865 4,10 C4,12.795 5.9125,15.1365 8.5,15.8035 L8.5,17 L11.5,17 L11.5,15.8035 C14.0855,15.1365 16,12.795 16,10 L16,10 Z"
              id="Stroke-6221"
            />
            <path d="M10.5,21 L9.5,21" id="Stroke-6222" />
            <path d="M11.5,19 L8.5,19" id="Stroke-6223" />
            <path d="M10,0 L10,2" id="Stroke-6224" />
            <path d="M20,10 L18,10" id="Stroke-6225" />
            <path d="M0,10 L2,10" id="Stroke-6226" />
            <path d="M2.2207,2.22215 L4.3422,4.34415" id="Stroke-6227" />
            <path d="M17.77735,2.22215 L15.65585,4.34415" id="Stroke-6228" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Insights;
