import { nodeListToArray } from './nodeListToArray';

export function wrapTables(
  contentEl,
  selector,
  wrapperClass = 'table-wrapper',
) {
  for (const table of nodeListToArray(contentEl?.querySelectorAll(selector))) {
    const wrapper = document.createElement('div');
    wrapper.classList.add(wrapperClass);
    table.setAttribute('wrapped', '');
    table.parentNode.insertBefore(wrapper, table);
    wrapper.appendChild(table);
  }
}
