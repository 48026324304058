import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import React from 'react';
import styled from 'styled-components';

export default function StatusMessage({ icon, ...props }) {
  return (
    <StyledStatusMessage className={`status-message ${props.className}`}>
      {icon ? <i className={`fa ${icon}`} /> : null}
      {props.children}
    </StyledStatusMessage>
  );
}

const StyledStatusMessage = styled.h3`
  color: ${CEB_COLOR('BLACK')};
  flex: 1 1 auto;
  font-family: 'Zilla Slab', serif;
  font-size: 19px;
  font-weight: normal;
  padding: 25px;
  min-height: 50vh;
  box-sizing: border-box;
  > i {
    margin-right: 0.5em;
    &.fa-exclamation-triangle {
      color: orange;
    }
  }
`;
