import React from 'react';
import useChangeOptionFilters from 'hooks/useChangeOptionFilters';
import useQueryString from 'hooks/useQueryString';
import useSuspendableQuery from 'hooks/useSuspendableQuery';
import getOr from 'lodash/fp/getOr';

export function useFilters(query, queryStringVariable, itemsPath) {
  const queryString = useQueryString();
  const { data } = useSuspendableQuery(query);
  const items = getOr([])(itemsPath)(data);

  const filterIds = React.useMemo(
    () =>
      (queryString &&
        queryString[queryStringVariable] &&
        queryString[queryStringVariable].split(',')) ||
      [],
    [queryString, queryStringVariable],
  );

  const filters = React.useMemo(
    () =>
      filterIds.reduce((filters, filter) => {
        filters[filter] = true;
        return filters;
      }, {}),
    [filterIds],
  );

  const changeOptionFilters = useChangeOptionFilters(queryStringVariable);

  const onOptionChange = React.useCallback(
    e => {
      const filterValue = e.target.name;
      filters[filterValue] = e.target.checked;
      changeOptionFilters(filters);
    },
    [filters, changeOptionFilters],
  );

  return [items, filters, onOptionChange];
}
