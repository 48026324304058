import { CLELibrarySearchInput } from 'components/cle-library/landing/CLELibrarySearchInput';
import { StyledSidebar } from 'components/cle-library/landing/CLELibrarySearchSidebar';
import React, { useCallback, useMemo } from 'react';
import { StyledExpandableSection } from 'components/cle-library/landing/StyledExpandableSection';
import { FilterOption } from 'components/practitioner/FilterOption';
import { CLELibraryPracticeAreaFilters } from 'components/cle-library/landing/CLELibraryPracticeAreaFilters';
import { useSearchParams } from 'react-router-dom';
import { gql } from '@apollo/client';

const GET_PRACTICE_AREAS_CONTRIBUTOR = gql`
  query getAllPracticeAreasContributor {
    contentfulPracticeArea(id: "2IJw5gDFab7ZY5e7dNpLyL") {
      name
      practiceAreaSubtopicsCollection {
        items {
          sys {
            id
          }
          name
          slug
          linkedFrom {
            contributorCollection {
              total
            }
          }
        }
      }
    }
  }
`;

const regions = [
  'Bay Area Region',
  'Capital Region',
  'Central Region',
  'Northern Region',
  'SoCal Region',
];

const reduceToObject = (p, c) => ({ ...p, [c]: true });

function CLELibraryRegionFilters() {
  const [searchParams, setSearchParams] = useSearchParams();
  const filters = useMemo(() => {
    const selectedRegions = searchParams.get('regions');

    return (selectedRegions ? selectedRegions.split(',') : []).reduce(
      reduceToObject,
      {},
    );
  }, [searchParams]);

  const onOptionChange = useCallback(
    e => {
      const filterValue = e.target.name;
      filters[filterValue] = e.target.checked;

      const nextFilters = Object.keys(filters)
        .map(f => filters[f] && f)
        .filter(Boolean);

      if (nextFilters.length > 0) {
        searchParams.set('regions', nextFilters);
      } else {
        searchParams.delete('regions');
      }
      searchParams.delete('page');

      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams, filters],
  );

  return (
    <StyledExpandableSection
      name="CEB Region"
      border={false}
      defaultExpanded
      cache
    >
      {regions.map(region => (
        <FilterOption
          key={region}
          filter={{
            filterValue: region,
            name: region,
            checked: filters[region] === true,
          }}
          onOptionChange={onOptionChange}
        />
      ))}
    </StyledExpandableSection>
  );
}

export function CLEContributorSearchSidebar() {
  return (
    <StyledSidebar>
      <CLELibrarySearchInput placeholder="Search Contributions" />
      <CLELibraryRegionFilters />
      <CLELibraryPracticeAreaFilters
        query={GET_PRACTICE_AREAS_CONTRIBUTOR}
        linkedFrom="contributorCollection"
      />
    </StyledSidebar>
  );
}
