import React from 'react';
import PropTypes from 'prop-types';
import serialize from 'form-serialize';
import values from 'lodash/fp/values';
import flow from 'lodash/fp/flow';
import filter from 'lodash/fp/filter';
import isEmpty from 'lodash/fp/isEmpty';
export { FormCheckbox } from './form_checkbox';
export { FormButton } from './form_button';
export { FormInput } from './form_input';
export { FormCols } from './form_cols';
export { FormSelect } from './form_select';

/**
 * JsonForm Stateless Component
 */

const isValid = flow(
  values,
  filter(v => v === false),
  isEmpty,
);

export interface FormContextValue {
  valid?: boolean;
  setFormState?: (name: string, valid: boolean) => void;
}

export const formContext = React.createContext<FormContextValue>({});

export interface JsonFormProps {
  onSubmit?: (
    result: Record<string, any>,
    event: React.ChangeEvent<HTMLFormElement>,
  ) => void;
  onChange?: (result: Record<string, any>) => void;
  onValidChange?: (valid: boolean) => void;
  children: React.ReactNode;
}
export function JsonForm({
  onSubmit,
  onChange,
  onValidChange,
  ...props
}: JsonFormProps) {
  const [formInputs, setInputs] = React.useState({});

  const valid = isValid(formInputs);

  const setFormState = React.useCallback(
    (name: string, valid: boolean) => {
      const inputs = Object.assign(formInputs, { [name]: valid });
      const formValid = isValid(inputs);
      onValidChange && onValidChange(formValid);
      setInputs(inputs);
    },
    [formInputs, onValidChange],
  );

  const changeHandler = React.useCallback(
    (event: React.ChangeEvent<HTMLFormElement>) => {
      const val = serialize(event.currentTarget, { hash: true });
      onChange && onChange(val);
    },
    [onChange],
  );

  const submitHandler = React.useCallback(
    (event: React.ChangeEvent<HTMLFormElement>) => {
      event.preventDefault();
      onSubmit && onSubmit(serialize(event.target, { hash: true }), event);
    },
    [onSubmit],
  );

  return (
    <formContext.Provider value={{ valid, setFormState }}>
      <form {...props} onSubmit={submitHandler} onChange={changeHandler}>
        {props.children}
      </form>
    </formContext.Provider>
  );
}

JsonForm.propTypes = {
  /** called when the form is submitted with the form values as a JSON object */
  onSubmit: PropTypes.func,
  /** called when the form changes with the form values as a JSON object */
  onChange: PropTypes.func,
};

JsonForm.defaultProps = {
  // eslint-disable-next-line no-console
  onSubmit: console.log,
};
