import React from 'react';
import omit from 'lodash/fp/omit';
import MaskedInput from 'react-maskedinput';

export const InputComponent = ({
  inputRef,
  handleInputChange,
  mask,
  placeholderChar,
  ...props
}) => {
  const inputProps = Object.assign(
    {},
    omit([
      'inputRef',
      'onChange',
      'setFormState',
      'setValid',
      'valid',
      'validate',
      'validator',
      'validateOn',
    ])(props),
  );

  return mask ? (
    <MaskedInput
      mask={mask}
      onChange={handleInputChange}
      placeholderChar={placeholderChar}
      ref={inputRef}
      {...inputProps}
    />
  ) : (
    <input onChange={handleInputChange} ref={inputRef} {...inputProps} />
  );
};
