import FilterIcon from 'components/common/icons/FilterIcon';
import React from 'react';
import styled from 'styled-components';
import { CLELibraryCourseFilters } from './CLELibraryCourseFilters';
import { CLELibrarySearchInput } from './CLELibrarySearchInput';

export function CLELibrarySearchSidebar() {
  return (
    <StyledSidebar>
      <CLELibrarySearchInput />
      <header>
        <FilterIcon />
        <h4>Filter By</h4>
      </header>
      <StyledCLELibraryCourseFilters />
    </StyledSidebar>
  );
}

const StyledCLELibraryCourseFilters = styled(CLELibraryCourseFilters)`
  font-family: 'BasisGrotesque', sans-serif;
  font-weight: 600;
`;

export const StyledSidebar = styled.div`
  background: #f7f7f7;
  box-sizing: border-box;
  border-radius: 0px 5px 0px 0px;
  padding: 0;
  height: 100%;
  overflow: auto;
  min-width: 305px;
  max-width: 305px;

  header {
    display: flex;
    margin: 26px 24px 0 24px;
    align-items: center;
    h4 {
      font-family: 'BasisGrotesque', sans-serif;
      font-size: 18px;
      font-weight: 900;
      color: #29364f;
      text-transform: uppercase;
      margin-left: 11px;
      margin-top: 2px;
    }
  }
`;
