import { CEB_COLOR, CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';
import React from 'react';
import styled from 'styled-components';

export const ArticleCopyright = () => (
  <StyledCopyrightSection>
    <p>
      &copy; The Regents of the University of California,{' '}
      {new Date().getFullYear()}.
    </p>
    <p>
      Unauthorized use and/or duplication of this material without express and
      written permission from CEB is strictly prohibited. CEB content does not
      render any legal, accounting, or other professional service; this content
      is not intended to describe the standard of care for attorneys in any
      community, but rather to assist attorneys in providing high quality
      service to their clients and in protecting their own interests. Attorneys
      using CEB content in dealing with a specific legal matter should also
      research original sources of authority. Any opinions contained in CEB
      content are not intended to reflect the position of the University of
      California. Materials written by employees of state or federal agencies
      are not to be considered statements of governmental policies.
    </p>
  </StyledCopyrightSection>
);

const StyledCopyrightSection = styled.section`
  color: ${CEB_COLOR_RGBA('BLACK', 0.7)};
  font-family: 'Zilla Slab', serif;
  font-size: 13px;
  font-style: italic;
  line-height: 1.38;
  margin-bottom: 48px;

  p:first-child {
    font-size: 14px;
    color: ${CEB_COLOR('BLACK')};
    margin-bottom: 1em;
  }
`;
