import React from 'react';
import classSet from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export interface FormColsProps {
  gutter?: number;
  breakAt?: number;
  className?: string;
  children?: React.ReactNode[] | React.ReactNode;
}

export function FormCols({
  gutter = 5,
  breakAt = 520,
  className,
  children,
}: FormColsProps) {
  const classes = {
    'form-cols': true,
    ...(className ? { [className]: true } : {}),
  };

  const wrappedChildren = Array.isArray(children) ? children : [children];
  const colTotal = wrappedChildren.filter(Boolean).length;

  return (
    <StyledFormCols
      className={classSet(classes)}
      colTotal={colTotal}
      gutter={gutter}
      breakAt={breakAt}
    >
      {wrappedChildren.filter(Boolean)?.map((el, i) => (
        <div className={`form-col form-col-${i}`} key={i}>
          {el}
        </div>
      ))}
    </StyledFormCols>
  );
}

interface StyledFormColsProps {
  breakAt?: number;
  gutter: number;
  colTotal?: number;
}
const StyledFormCols = styled.div<StyledFormColsProps>`
  .form-col {
    margin-right: 0;
    margin-bottom: 5px;
  }
  @media only screen and (min-width: ${({ breakAt }) => breakAt}px) {
    &.form-cols {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      align-content: stretch;
      align-items: flex-start;
    }

    .form-col {
      align-self: stretch;
      flex: 1 1 auto;
      box-sizing: border-box;
      padding-right: ${({ gutter }) => gutter / 2}px;
      padding-left: ${({ gutter }) => gutter / 2}px;
      max-width: ${({ colTotal = 1 }) => 100 / colTotal + '%'};
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
`;
