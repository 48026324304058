export function nodeListToArray(list) {
  return [].slice.call(list);
}

export function toNodeList(arrayOfNodes) {
  var fragment = document.createDocumentFragment();
  if (arrayOfNodes?.length > 0)
    arrayOfNodes.forEach(function(item) {
      console.log(item);
      fragment.appendChild(item.cloneNode());
    });
  return fragment.childNodes;
}
