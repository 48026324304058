import React from 'react';
import styled, { css } from 'styled-components';
import { UserIcon } from 'components/common/icons';
import { usePrintHandlers } from 'hooks/usePrintHandlers';

function contributorUrl(slug, prefix = 'https://') {
  return `${prefix}/contributor/${slug}`;
}

export const AuthorNames = ({
  authorsCollection,
  hideIcon = false,
  headshotWidth,
}) => {
  const [isPrinting] = usePrintHandlers();
  const authors = [...(authorsCollection?.items || [])].filter(Boolean);
  return (
    <StyledAuthorNames
      className="authors"
      hideIcon={hideIcon}
      headshotWidth={headshotWidth}
    >
      <dt>
        <UserIcon
          height="24"
          width="24"
          path={isPrinting ? '#000000' : 'rgba(31, 34, 106, 0.8)'}
          circle={isPrinting ? '#ffffff' : '#d8d8d8'}
          className="icon"
        />
      </dt>
      {(authors || []).map((author, i) => (
        <React.Fragment key={`${author.webDisplayName}-${i}`}>
          {!!headshotWidth && (author.headshotUrl || author.headshot?.url) && (
            <dt className="headshot">
              <img
                src={author.headshotUrl || author.headshot?.url}
                alt={`${author.webDisplayName} headshot`}
              />
            </dt>
          )}
          <dd>
            {i > 0 && i === authors.length - 1 ? (
              <span className="conjoiner">and</span>
            ) : null}
            {author.webDisplayName}
            {i + 1 < authors.length - 1 ? (
              <span className="separator">,</span>
            ) : null}
          </dd>
        </React.Fragment>
      ))}
    </StyledAuthorNames>
  );
};

const StyledAuthorNames = styled.dl`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  > dd,
  > dt {
    flex: 0 1 auto;
  }
  &:last-child {
    text-align: right;
  }
  dd {
    font-weight: bold;
  }
  .icon {
    ${({ hideIcon }) => (hideIcon ? 'display: none;' : null)}
  }
  dt {
    vertical-align: -7px;
  }
  dt,
  dd {
    display: inline-block;
    .conjoiner {
      margin: 0 4px;
    }
    .separator {
      margin: 0 4px 0 0;
    }
  }
  dt + dd {
    margin-left: ${({ hideIcon, headshotWidth }) =>
      hideIcon && !headshotWidth ? 0 : '6px'};
  }
  ${({ headshotWidth = 24 }) =>
    headshotWidth &&
    css`
      dt.headshot {
        width: ${headshotWidth}px;
        border-radius: ${headshotWidth}px;
        overflow: hidden;
        img {
          line-height: 1;
          display: block;
          max-width: 100%;
        }
      }
    `}
`;
