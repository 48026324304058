import { gql } from '@apollo/client';
import React from 'react';
import { useContributionQuery } from './useContributionQuery';
import { LawAlertPlaceholder } from 'components/daily-news/LawAlertPlaceholder';
import { FormattedTime } from 'components/common/FormattedTime';
import { AuthorNames } from 'components/common/AuthorNames';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { truncateToNearestWord } from 'util/truncateToNearestWord';
import { orderBy } from 'lodash/fp';
import { PracticeAreaTags } from 'components/daily-news/PracticeAreaTags';
import { usePracticeAreaRootSlugs } from 'components/cle-library/usePracticeAreaRootSlugs';

const truncate = truncateToNearestWord(300, ' …');

const GET_DAILY_NEWS_CONTRIBUTIONS = gql`
  query getDailyNewsContributions($slug: String!) {
    contentfulContributorCollection(where: { slug: $slug }, limit: 1) {
      items {
        linkedFrom {
          currentAwarenessCollection(limit: 500) {
            total
            items {
              slug
              title
              subtitle
              featuredImage {
                url
              }
              originalPublishDate
              authorsCollection(limit: 5) {
                items {
                  webDisplayName
                }
              }
              practiceAreasCollection(limit: 10) {
                items {
                  sys {
                    id
                  }
                  name
                  slug
                }
              }
              content {
                json
              }
            }
          }
        }
      }
    }
  }
`;

const byPublishedDate = orderBy(['originalPublishDate'])('desc');

export function DailyNewsContributions({ slug }) {
  const items = useContributionQuery(
    GET_DAILY_NEWS_CONTRIBUTIONS,
    slug,
    'currentAwarenessCollection',
  );

  const rootSlugs = usePracticeAreaRootSlugs();

  return (
    <StyledContributionsList>
      {byPublishedDate(items).map((item, i) => (
        <DailyNewsContribution
          item={item}
          rootSlugs={rootSlugs}
          key={`currentAwarenessCollection-${i}`}
        />
      ))}
    </StyledContributionsList>
  );
}
const practiceAreaPathbuilder = area => `/?practiceAreas=${area.sys.id}`;

function DailyNewsContribution({ item, rootSlugs }) {
  const practiceAreas = item.practiceAreasCollection.items.filter(area =>
    rootSlugs.includes(area.slug),
  );
  return (
    <StyledPostCard>
      <a
        href={`https://research.ceb.com/posts/${item.slug}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {item.featuredImage ? (
          <img src={item.featuredImage.url} alt="" />
        ) : (
          <LawAlertPlaceholder />
        )}
      </a>

      <div>
        <h3>
          <a
            href={`https://research.ceb.com/posts/${item.slug}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item.title}
          </a>
        </h3>
        {item.subtitle && <h4>{item.subtitle}</h4>}
        <section>
          <AuthorNames
            authorsCollection={{ items: item.authorsCollection.items }}
            hideIcon
          />
          <FormattedTime time={item.originalPublishDate} flex />
        </section>
        <p>{truncate(documentToPlainTextString(item.content.json))}</p>
        {/* <ContentfulContent content={item.content} /> */}
        {practiceAreas && (
          <PracticeAreaTags
            practiceAreas={practiceAreas}
            pathBuilder={practiceAreaPathbuilder}
          />
        )}
      </div>
    </StyledPostCard>
  );
}

const StyledContributionsList = styled.ul`
  margin-top: 48px;
`;

const StyledPostCard = styled.li`
  margin-bottom: 53px;
  display: flex;
  gap: 24px;

  > a:first-child {
    width: 279px;
  }
  div {
    flex: 1 1 auto;
    width: 100%;
    box-sizing: border-box;
    + div {
      flex: 0 1 auto;
    }
    img {
      width: 100%;
    }

    @media only screen and (min-width: $720px) {
      max-width: 279px;
      + div {
        max-width: calc(100% - 279px);
        padding-left: 40px;
        width: auto;
      }
    }
  }

  section {
    display: flex;
    color: ${CEB_COLOR('CEB_HEADER_BLUE')};
    font-family: 'BasisGrotesque', sans-serif;
    font-size: 14px;
    font-style: italic;
    margin-bottom: 9px;
    .authors {
      flex: 0 1 auto;
      font-weight: bold;
      margin-right: 0.8em;
    }
  }

  h3 {
    font-family: LyonDisplay, serif;
    font-size: 24px;
    line-height: 1.25;
    margin-bottom: 8px;
    a,
    a:visited,
    a:active {
      color: ${CEB_COLOR('BLACK_TWO')};
    }
  }

  h4 {
    font-family: 'Bitter', serif;
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
    margin-bottom: 14px;
    margin-top: -6px;
  }

  p {
    font-family: 'Zilla Slab', serif;
    font-size: 15px;
    line-height: 1.6;
  }
`;
