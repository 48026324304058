import React from 'react';
import styled, { css } from 'styled-components';
import { CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';
import { ModalOverlay } from 'components/common/modal-overlay';
import { ModalHeader } from 'components/common/modal-overlay/ModalHeader';
import { TrueCiteLogo } from './TrueCiteLogo';
import colorForAttitude from 'util/colorForAttitude';

const TRUECITE_GUIDE_MAP = {
  Negative:
    'Indicates the case is no longer good on at least one point of law (e.g., disapproved, overruled, superseded, disavowed, or some other directly negative treatment).',
  Warning:
    'A subsequent case disputed the cited case’s precedential value (e.g., criticized, limited, disagreed with, declined to extend, declined to follow, validity questioned).',
  Caution:
    'Indicates the case is good law, but is not binding due to different facts (e.g., distinguished, cited for contrast, not applicable).',
  'Neutral or Positive':
    'Indicates the case is being cited or discussed for background legal principles or context; may also indicate more positive treatment, like upholding the case or relying on it.',
  'No Treatment':
    'Case has no citator treatment, either because it is a brand new case or no subsequent cases have referenced it yet.',
};

const TRUECITE_GUIDE_COLOR_MAP = {
  Negative: colorForAttitude('NEGATIVE'),
  Warning: colorForAttitude('WARNING'),
  Caution: colorForAttitude('CAUTION'),
  'Neutral or Positive': colorForAttitude('DISCUSSED'),
  'No Treatment': colorForAttitude('UNKNOWN'),
};

export function TrueCiteHelpModalButton() {
  const [modalVisible, setModalVisible] = React.useState(false);
  const closeModal = React.useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      setModalVisible(false);
    },
    [setModalVisible],
  );
  const openModal = React.useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      e.nativeEvent.stopImmediatePropagation();
      setModalVisible(true);
    },
    [setModalVisible],
  );

  return (
    <>
      <StyledHelpButton onClick={openModal}>
        <i className="fas fa-question-circle" />
      </StyledHelpButton>
      <ModalOverlay
        minHeight="550px"
        maxWidth="888px"
        contentHeight="auto"
        visible={modalVisible}
        closeModal={closeModal}
      >
        <TrueCiteModalContent closeModal={closeModal} />
      </ModalOverlay>
    </>
  );
}

function TrueCiteModalContent({ closeModal }) {
  return (
    <StyledTrueCiteModalContent>
      <ModalHeader close={closeModal}>
        <h3>
          <TrueCiteLogo text={false} /> Guide to TrueCite&trade; Color Coding
        </h3>
      </ModalHeader>
      <div>
        <dl className="true-cite-guide">
          {Object.keys(TRUECITE_GUIDE_MAP).map(key => {
            const keyClassName = key.replace(/\s/g, '-').toLowerCase();
            return (
              <React.Fragment key={keyClassName}>
                <dt className={keyClassName}>
                  <h5>{key}</h5>
                </dt>
                <dd>{TRUECITE_GUIDE_MAP[key]}</dd>
              </React.Fragment>
            );
          })}
        </dl>
      </div>
    </StyledTrueCiteModalContent>
  );
}

export const StyledTrueCiteModalContent = styled.section`
  padding: 35px 35px 10px 35px;
  header {
    margin-bottom: 20px;
    button.close {
      font-size: 20px;
      margin-right: 3px;
      line-height: 1em;
      margin-top: -2px;
    }
  }
  h3 {
    font-size: 20px;
    margin-bottom: 20px;
    line-height: 1;
    svg {
      margin: 0;
      font-size: 26px;
    }
  }
  .true-cite-guide {
    display: flex;
    flex-wrap: wrap;
    dt {
      flex: 0 1 auto;
      max-width: 18%;
      min-width: 18%;
      margin-top: 7px;
      text-align: right;
      h5 {
        background: red;
        border-radius: 2.3em;
        color: white;
        display: inline-block;
        font-family: 'Lato', sans-serif;
        font-size: 12px;
        line-height: 1em;
        margin-right: 2.25em;
        padding: 0.5em 0.7em;
      }
      ${Object.keys(TRUECITE_GUIDE_COLOR_MAP).map(key => {
        return css`
          &.${key.replace(/\s/g, '-').toLowerCase()} h5 {
            background: ${TRUECITE_GUIDE_COLOR_MAP[key]};
          }
        `;
      })}
    }
    dd {
      flex: 1 1 auto;
      line-height: 1.65em;
      margin-bottom: 35px;
      max-width: 82%;
      min-width: 82%;
    }
  }
`;

const StyledHelpButton = styled.button`
  color: ${CEB_COLOR_RGBA('DARK_GREY_BLUE', 0.19)};
`;
