import React from 'react';
import StyledFilterOption from 'components/common/search/sidebar/filters/StyledFilterOption';

export interface FilterOptionFilter {
  filterValue: string;
  name: string;
  children?: FilterOptionFilter[];
  linkedFrom?: any;
  indeterminate?: boolean;
  checked?: boolean;
}

export interface FilterOptionProps {
  filter: FilterOptionFilter;
  onOptionChange?: React.ChangeEventHandler<HTMLInputElement>;
  children?: React.ReactNode;
}

export const FilterOption = ({
  filter,
  onOptionChange,
  children,
}: FilterOptionProps) => {
  return (
    <StyledFilterOption className="option">
      <label>
        <input
          type="checkbox"
          name={filter.filterValue}
          onChange={onOptionChange}
          checked={filter.checked}
          ref={elem =>
            elem && (elem.indeterminate = filter.indeterminate === true)
          }
        />
        {filter.name}
      </label>
      {children}
      {filter.children
        ? filter.children.map((filter, key) => (
            <FilterOption
              key={key}
              filter={filter}
              onOptionChange={onOptionChange}
            />
          ))
        : null}
    </StyledFilterOption>
  );
};
