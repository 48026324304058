import React from 'react';
import styled from 'styled-components';
import { CEB_COLOR, CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';
import { FeaturedContributorSlide } from './FeaturedContributorSlide';
import { useFeaturedContributors } from './useFeaturedContributors';
import { CarouselHeader } from './CarouselHeader';
import { findParent } from 'util/finder';

export function CEBContributorCarousel() {
  const featuredContributors = useFeaturedContributors();

  return (
    <StyledCarouselContainer>
      <CarouselHeader />
      <Carousel total={featuredContributors.length}>
        {featuredContributors.map(contributor => {
          return (
            <FeaturedContributorSlide
              key={contributor.slug}
              contributor={contributor}
            />
          );
        })}
      </Carousel>
    </StyledCarouselContainer>
  );
}

function Carousel({ children, secondsPerSlide = 7.531 }) {
  const [current, setCurrent] = React.useState(0);
  const [shifting, setShifting] = React.useState(false);

  const itemsContainer = React.useRef();
  const slideTimer = React.useRef();
  const slideRestartTimer = React.useRef();

  const nextSlide = React.useCallback(() => {
    setCurrent(current => current + 1);
  }, [setCurrent, children]);

  const handleButtonClick = React.useCallback(
    e => {
      if (shifting) return;
      const button =
        e.target.tagName.toLowerCase() === 'button'
          ? e.target
          : findParent(e.target)('button');
      const offset = button.dataset.action === 'next' ? 1 : -1;
      button.blur();
      setShifting(true);
      setCurrent(current + offset);
      clearInterval(slideTimer.current);
    },
    [shifting, setCurrent, children],
  );

  const handleSliderDotClick = React.useCallback(
    (e, i) => {
      e.target && e.target.blur();
      setShifting(true);
      setCurrent(i);
    },
    [setCurrent],
  );

  const afterTransition = React.useCallback(() => {
    setShifting(false);
    if (current >= children.length) {
      setCurrent(0);
    }
    if (current === -1) {
      setCurrent(children.length - 1);
    }
  }, [current, setShifting, children.length]);

  React.useEffect(() => {
    itemsContainer.current &&
      itemsContainer.current.addEventListener('transitionend', afterTransition);
    return () => {
      itemsContainer.current &&
        itemsContainer.current.removeEventListener(
          'transitionend',
          afterTransition,
        );
    };
  }, [afterTransition]);

  const startCarousel = React.useCallback(() => {
    clearInterval(slideTimer.current);
    slideTimer.current = setInterval(() => {
      setShifting(true);
      nextSlide();
    }, secondsPerSlide * 1000);
    return () => {
      clearInterval(slideTimer.current);
    };
  }, [setShifting, nextSlide, secondsPerSlide]);

  React.useEffect(startCarousel, [startCarousel]);

  const handleMouseEnter = React.useCallback(() => {
    clearTimeout(slideRestartTimer.current);
    clearInterval(slideTimer.current);
  }, []);

  const handleMouseLeave = React.useCallback(() => {
    clearTimeout(slideRestartTimer.current);
    setTimeout(startCarousel, secondsPerSlide * 1000 * 2);
  }, [startCarousel, secondsPerSlide]);

  return (
    <StyledCarousel
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <StyledCarouselItems
        className={shifting && 'shifting'}
        ref={itemsContainer}
      >
        <CarouselChildren current={current}>{children}</CarouselChildren>
      </StyledCarouselItems>
      <StyledCarouselDots>
        {(children.length ? children : [children]).map((item, i) => {
          const isCurrent =
            i === current ||
            (i === 0 && current === children.length) ||
            (i === children.length - 1 && current === -1);
          return (
            <li key={`carousel-dot-${i}`}>
              <button
                className={isCurrent ? 'active' : ''}
                onClick={e => handleSliderDotClick(e, i)}
              />
            </li>
          );
        })}
      </StyledCarouselDots>
      <StyledCarouselButton onClick={handleButtonClick} data-action="prev">
        <i className="fa fa-chevron-left" />
      </StyledCarouselButton>
      <StyledCarouselButton onClick={handleButtonClick} data-action="next">
        <i className="fa fa-chevron-right" />
      </StyledCarouselButton>
    </StyledCarousel>
  );
}

function CarouselChildren({ children, current }) {
  return (
    <>
      <StyledCarouselItem $current={current} $slideIndex={-1}>
        {children[children.length - 1]}
      </StyledCarouselItem>
      {children.map((child, i) => {
        return (
          <StyledCarouselItem
            key={`slide-item-${i}`}
            $current={current}
            $slideIndex={i}
          >
            {child}
          </StyledCarouselItem>
        );
      })}
      <StyledCarouselItem $current={current} $slideIndex={children.length}>
        {children[0]}
      </StyledCarouselItem>
    </>
  );
}

const StyledCarouselContainer = styled.section`
  width: 100%;
  padding: 0 60px;
  margin: 0 auto;
`;

const StyledCarousel = styled.section`
  position: relative;
  margin-bottom: 96px;
  background: rgba(255, 255, 255, 0.67);
`;

const StyledCarouselButton = styled.button`
  background: ${CEB_COLOR_RGBA('BLUE_RIBBON', 0.15)};
  border-radius: 100%;
  color: ${CEB_COLOR('NICE_BLUE')};
  display: inline-block;
  font-size: 24px;
  height: 54px;
  left: -54px;
  position: absolute;
  right: auto;
  top: calc(50% - 38px);
  width: 54px;
  z-index: 10;
  &:focus {
    outline: 1px solid ${CEB_COLOR('NICE_BLUE')};
  }
  + button {
    right: -54px;
    left: auto;
  }
`;

const StyledCarouselItems = styled.ol`
  width: calc(100% + 96px);
  position: relative;
  height: 320px;
  padding: 0 48px;
  box-sizing: border-box;
  overflow: hidden;
  margin-left: -48px;
  transform: matrix3d(0, 0, 0);

  &:before,
  &:after {
    display: block;
    background: linear-gradient(90deg, white, transparent);
    content: ' ';
    height: 100%;
    width: 48px;
    position: absolute;
    top: 0;
    z-index: 5;
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
    background: linear-gradient(-90deg, white, transparent);
  }
`;

const StyledCarouselItem = styled.li`
  height: 320px;
  width: 100%;
  position: absolute;
  display: inline-block;
  left: ${({ $slideIndex, $current }) => ($slideIndex - $current) * 100}%;
  z-index: 1;
  margin-left: 96px;
  transform: matrix3d(0, 0, 0);

  .shifting & {
    transition: left 1s ease;
  }
`;

const StyledCarouselDots = styled.ul`
  list-style-type: none;
  width: 100%;
  text-align: center;
  margin-top: 24px;
  li {
    display: inline-block;
    button {
      &:hover {
        background: ${CEB_COLOR_RGBA('BLUE_RIBBON', 0.45)};
      }

      &.active {
        background: ${CEB_COLOR('BLUE_RIBBON')};
      }
      background: ${CEB_COLOR_RGBA('BLUE_RIBBON', 0.15)};
      border-radius: 12px;
      display: inline-block;
      height: 12px;
      margin: 0 4px;
      overflow: hidden;
      transition: background-color 0.25s ease-in-out;
      width: 12px;
    }
  }
`;
