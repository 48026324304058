import React from 'react';
import { TrackedLink as Link } from 'components/common/TrackedLink';
import { FormattedTime } from 'components/common/FormattedTime';
import { AuthorNames } from 'components/common/AuthorNames';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

export const RelatedPosts = ({ items }) => {
  return (
    <StyledRelatedPosts>
      <h4>Related</h4>
      <div>
        {items.map((relItem, i) => (
          <div key={i}>
            <Link to={`/posts/${relItem.slug}`}>
              <h5>{relItem.title}</h5>
              <span>
                <AuthorNames
                  authorsCollection={relItem.authorsCollection}
                  hideIcon
                />
                <FormattedTime
                  time={relItem.originalPublishDate}
                  format="MMM DD, YYYY"
                  flex
                />
              </span>
            </Link>
          </div>
        ))}
      </div>
    </StyledRelatedPosts>
  );
};

export const StyledRelatedPosts = styled.section`
  h4 {
    font-family: 'BasisGrotesque', sans-serif;
    font-size: 16px;
    font-weight: 900;
    color: ${CEB_COLOR('BLACK_TWO')};
    margin-bottom: 19px;
    text-transform: uppercase;
  }
  a,
  a:visited {
    font-family: 'Bitter', serif;
    color: ${CEB_COLOR('BLACK_TWO')};
  }
  h5 {
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    color: ${CEB_COLOR('BLACK_TWO')};
    margin-bottom: 8px;
  }
  span {
    display: flex;
  }
  > div {
    display: flex;
    div {
      flex: 1 1 auto;
      max-width: 33.33%;
      + div {
        margin-left: 24px;
      }
      .authors {
        color: ${CEB_COLOR('CEB_HEADER_BLUE')};
        flex: 0 1 auto;
        font-family: 'BasisGrotesque', sans-serif;
        font-size: 13px;
        font-style: italic;
        font-weight: bold;
        margin-right: 1em;
      }
      time {
        color: ${CEB_COLOR('CEB_HEADER_BLUE')};
        font-family: 'BasisGrotesque', sans-serif;
        font-size: 13px;
        font-style: italic;
      }
    }
  }
`;
