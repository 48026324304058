import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel } from '../FormLabel';
import styled from 'styled-components';

export const FormSelect = props => {
  return (
    <StyledFormSelect className={props.className}>
      {props.label ? (
        <FormLabel {...props}>
          <select {...props}>
            {props.placeholder ? (
              <option value="">{props.placeholder}</option>
            ) : null}
            {props.children}
          </select>
        </FormLabel>
      ) : (
        <select {...props}>
          {props.placeholder ? (
            <option value="">{props.placeholder}</option>
          ) : null}
          {props.children}
        </select>
      )}
    </StyledFormSelect>
  );
};

const StyledFormSelect = styled.div`
  margin-bottom: 5px;
  text-align: left;
  &:last-child {
    margin-bottom: 0;
  }

  > select {
    border: 1px solid;
    box-sizing: border-box;
    font-family: 'Zilla Slab', sans-serif;
    line-height: 1;
    padding: 4px 10px;
    width: 100%;
    box-sizing: border-box;
    > option {
      color: #000;
    }
  }
`;

FormSelect.propTypes = {
  /** change handler for use outside of forms */
  onChange: PropTypes.func,
};
FormSelect.defaultProps = {};
