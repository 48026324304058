import React from 'react';
import capitalize from 'lodash/fp/capitalize';
import getOr from 'lodash/fp/getOr';
import { pluralizeForKey } from 'util/pluralizeForKey';
import { LoadingEllipses } from '../LoadingEllipses';
import styled from 'styled-components';

const getCurrentPage = getOr(0, 'pageInfo.currentPage');
const getTotalCount = getOr(0, 'totalCount');

export function SearchResultsPageInfo({ searchKey = 'search', ...props }) {
  const data = props.data && props.data[searchKey];
  if (!data) {
    return <LoadingEllipses />;
  }
  const currentPage = getCurrentPage(data);
  const totalCount = getTotalCount(data);
  const resultTypeMessage =
    props.resultTypeMessage || `${capitalize(props.type)}`;
  const pluralizedType = pluralizeForKey('totalCount', resultTypeMessage);
  let resultMax = currentPage * props.perPage;
  if (resultMax > totalCount) {
    resultMax = totalCount;
  }
  return (
    <StyledSearchResultsPageInfo
      color={props.color || 'inherit'}
      className={props.className}
    >
      {totalCount > 0 ? (
        <>
          <b>
            {(currentPage * props.perPage - 9).toLocaleString()}-
            {resultMax.toLocaleString()}
          </b>{' '}
          of <b>{totalCount.toLocaleString()}</b> {pluralizedType(data)}
        </>
      ) : (
        <>
          <b>0</b> Results
        </>
      )}
      {props.children}
    </StyledSearchResultsPageInfo>
  );
}

const StyledSearchResultsPageInfo = styled.h4.attrs(({ color }) => ({ color }))`
  font-weight: normal;
  margin-bottom: 20px;
  font-size: 18px;
  b {
    font-weight: bold;
  }
  color: ${({ color }) => color};
`;
