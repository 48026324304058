import React from 'react';
import Book from 'components/common/icons/Book';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

export function SecondarySourcesContributions({
  publicationContributionBooks,
}) {
  return (
    <StyledSecondarySourceItems>
      {publicationContributionBooks.map(book => (
        <SecondarySourceItem book={book} key={book.onlawProProductCode} />
      ))}
    </StyledSecondarySourceItems>
  );
}

const StyledSecondarySourceItems = styled.ul`
  margin-top: 48px;
`;

function SecondarySourceItem({ book }) {
  const bookAuthorship = React.useMemo(() => {
    return [
      book.originalAuthor && 'Original Author',
      book.updateAuthor && 'Update Author',
    ]
      .filter(Boolean)
      .join(', ');
  }, [book]);

  const chapterAuthorship = React.useMemo(() => {
    return book.publicationContributionChapters.map(chapter => {
      return [
        chapter.originalAuthor && 'Original Author',
        chapter.updateAuthor && 'Update Author',
      ]
        .filter(Boolean)
        .join(', ');
    });
  }, [book]);

  return (
    <StyledBookItem>
      <div>
        <Book />
      </div>
      <div>
        <dl>
          <dt>
            <a
              href={`https://research.ceb.com/secondary-sources/area/${
                book.primaryPracticeAreaLibrary.slug
              }/${book.onlawProProductCode}`}
            >
              {book.name}
            </a>
          </dt>
          <dd className="area">{book.primaryPracticeAreaLibrary.name}</dd>
          <dd>
            <em>{bookAuthorship}</em>
          </dd>
          {book.publicationContributionChapters.map((chapter, i) => {
            return (
              <dd className="chapter" key={chapter.onlawId}>
                <b>{chapter.name}</b>{' '}
                {chapterAuthorship[i].length && <em>{chapterAuthorship[i]}</em>}
              </dd>
            );
          })}
        </dl>
      </div>
    </StyledBookItem>
  );
}

const StyledBookItem = styled.li`
  display: flex;
  margin-bottom: 36px;
  > div {
    flex: 0 1 auto;
    + div {
      margin-left: 24px;
    }
  }
  dl {
    font-family: 'Zilla Slab', serif;
  }
  dt {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
    a {
      color: ${CEB_COLOR('CEB_HEADER_BLUE')};
    }
  }
  b {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }
  dd {
    &.area {
      font-weight: bold;
      font-size: 15px;
      margin-bottom: 6px;
    }
    &.chapter {
      font-size: 15px;
    }
  }
`;
