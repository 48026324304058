import React from 'react';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

export function CarouselHeader() {
  return (
    <StyledContributorLandingHeader>
      <div>
        <h2>1,100 Contributors</h2>
        <h3>
          <em>from</em> 58 counties across California
        </h3>
      </div>
      <div>
        <p>
          CEB Contributors are lawyers and judges that shape the overall legal
          discourse in California, which is emulated throughout the world.
        </p>
      </div>
    </StyledContributorLandingHeader>
  );
}
export const StyledContributorLandingHeader = styled.header`
  margin-bottom: 60px;
  margin: 56px auto 60px auto;
  padding-left: 54px;
  @media screen and (min-width: 1166px) {
    display: flex;
    > div {
      flex: 0 1 auto;
      + div {
        padding-left: 140px;
      }
    }
  }

  h2 {
    font-family: LyonDisplay;
    font-size: 64px;
    line-height: 1.09;
    margin-top: 8px;
    white-space: nowrap;
  }

  h3 {
    color: ${CEB_COLOR('CEB_HEADER_BLUE')};
    font-family: LyonDisplay;
    font-size: 36px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    line-height: 1.94;
    padding-left: 31px;
    margin-top: -8px;
    white-space: nowrap;
    em {
      font-weight: 300;
      font-style: italic;
    }
  }
  p {
    font-family: 'Zilla Slab', serif;
    font-size: 22px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    line-height: 1.45;
    margin: 22px 0 0 0;
  }
`;
