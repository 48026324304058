import { stringify, parse as _parse } from 'qs';

function parse(queryString) {
  return _parse(queryString?.substr(1));
}

export default {
  stringify,
  parse,
};
