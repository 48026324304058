import { gql } from '@apollo/client';

export const GET_SPECIALIZATION_CREDIT_CATEGORIES = gql`
  query getAllSpecializationCreditCategories {
    contentfulCourseCreditCategoryCollection(
      where: { type: "LSCLE Specialization Category" }
      order: name_ASC
    ) {
      items {
        sys {
          id
        }
        name
        childCategoriesCollection {
          items {
            name
          }
        }
      }
    }
  }
`;
