import React, { useMemo } from 'react';
import StyledFilterOption from 'components/common/search/sidebar/filters/StyledFilterOption';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import { TrackedLink as Link } from 'components/common/TrackedLink';

export function YourPracticeAreas({ userPracticeAreas, practiceAreas }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    userPracticeAreasId,
    practiceAreaIds,
    anyChecked,
    allChecked,
  } = useMemo(() => {
    const userPracticeAreasId = userPracticeAreas.map(area => area.sys.id);
    const practiceAreaIds = Object.keys(practiceAreas);
    const anyChecked = userPracticeAreasId.some(id =>
      practiceAreaIds.includes(id),
    );
    const allChecked = userPracticeAreasId.every(id =>
      practiceAreaIds.includes(id),
    );
    return {
      userPracticeAreasId,
      practiceAreaIds,
      anyChecked,
      allChecked,
    };
  }, [userPracticeAreas, practiceAreas]);

  const checked = React.useMemo(
    () => searchParams.get('yourPracticeAreas') === 'true',
    [searchParams],
  );

  const onToggle = React.useCallback(() => {
    if (searchParams.get('yourPracticeAreas')) {
      searchParams.delete('yourPracticeAreas');
      searchParams.set(
        'practiceAreas',
        practiceAreaIds.filter(id => !userPracticeAreasId.includes(id)),
      );
    } else searchParams.set('yourPracticeAreas', 'true');
    if (searchParams.get('practiceAreas') === '')
      searchParams.delete('practiceAreas');
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams, practiceAreaIds, userPracticeAreasId]);

  const onToggleSubOption = React.useCallback(
    e => {
      if (!e.target.checked && checked) {
        const updatedPracticeIds = practiceAreaIds.concat(
          userPracticeAreasId.filter(id => id !== e.target.value),
        );
        searchParams.delete('yourPracticeAreas');
        searchParams.set('practiceAreas', updatedPracticeIds);
      } else {
        const updatedPracticeIds = e.target.checked
          ? practiceAreaIds.concat([e.target.value])
          : practiceAreaIds.filter(id => id !== e.target.value);

        const allChecked = userPracticeAreasId.every(id =>
          updatedPracticeIds.includes(id),
        );

        if (allChecked) {
          searchParams.set('yourPracticeAreas', 'true');
          searchParams.set(
            'practiceAreas',
            practiceAreaIds.filter(id => !userPracticeAreasId.includes(id)),
          );
        } else {
          searchParams.set('practiceAreas', updatedPracticeIds);
        }
      }

      if (searchParams.get('practiceAreas') === '')
        searchParams.delete('practiceAreas');

      setSearchParams(searchParams);
    },
    [
      checked,
      userPracticeAreasId,
      practiceAreaIds,
      searchParams,
      setSearchParams,
    ],
  );

  return (
    userPracticeAreas?.length > 0 && (
      <StyledFilterOption className="option">
        <EditButtonLabel>
          <input
            type="checkbox"
            name="yourPracticeAreas"
            ref={elem =>
              elem && (elem.indeterminate = anyChecked && !allChecked)
            }
            checked={checked || allChecked}
            onChange={onToggle}
          />
          Your Practice Areas
          <Link to="/account/profile">Edit</Link>
        </EditButtonLabel>

        {userPracticeAreas.map(area => {
          const subOptionChecked =
            checked || practiceAreas[area.sys.id || area.contentfulId] === true;

          return (
            <StyledFilterOption
              className="option"
              key={`your-practice-areas-option-${area.slug}`}
            >
              <label>
                <input
                  type="checkbox"
                  name="practiceAreas[]"
                  value={area.sys.id || area.contentfulId}
                  checked={subOptionChecked}
                  onChange={onToggleSubOption}
                />
                {area.name}
              </label>
            </StyledFilterOption>
          );
        })}
      </StyledFilterOption>
    )
  );
}

const EditButtonLabel = styled.label`
  &:hover {
    a {
      visibility: visible;
    }
  }

  a,
  a:visited {
    visibility: hidden;
    background: white;
    color: ${CEB_COLOR('NICE_BLUE')};
    font-family: 'Zilla Slab', serif;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 8px;
    margin-left: 4px;
    border-radius: 4px;
    box-shadow: 1px 1px 3px 0 rgb(31 34 106 / 22%),
      2px 2px 8px 0 rgb(31 34 106 / 8%);
  }
`;
