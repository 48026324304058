import { gql } from '@apollo/client';
import UserFragments from 'graphql/fragments/user';

export const GET_CONTENTFUL_PRACTICE_AREAS = gql`
  query getAllContentfulPracticeAreas {
    contentfulPracticeArea(id: "2IJw5gDFab7ZY5e7dNpLyL") {
      ...ContentfulPracticeAreas
    }
  }
  ${UserFragments.ContentfulPracticeAreas}
`;

export const GET_DAILY_NEWS_AREAS = gql`
  query getDailyNewsPracticeAreas {
    contentfulPracticeArea(id: "2IJw5gDFab7ZY5e7dNpLyL") {
      ...ContentfulPracticeAreas
    }
    user {
      ...UserPracticeAreas
    }
  }
  ${UserFragments.UserPracticeAreas}
  ${UserFragments.ContentfulPracticeAreas}
`;

export const GET_CURRENT_AWARENESS_SEARCH = gql`
  query getCurrentAwarenessSearch(
    $practiceAreaIds: [ID]!
    $page: Int!
    $date: String!
    $query: String
    $resourceTypes: [String]!
  ) {
    search: currentAwarenessSearch(
      filters: {
        PracticeAreaIds: $practiceAreaIds
        originalPublishDate: $date
        PostResourceTypes: $resourceTypes
      }
      pageSize: 10
      pageNumber: $page
      query: $query
    ) {
      totalCount
      pageInfo {
        totalPages
        currentPage
      }
      edges {
        contentfulId
        slug
        title
        subtitle
        featureImageUrl
        originalPublishDate
        authors {
          webDisplayName
        }
        postResourceType
        practiceAreas {
          name
          slug
          contentfulId
        }
        content
      }
    }
  }
`;
