import React from 'react';
import { gql } from '@apollo/client';
import useSuspendableQuery from 'hooks/useSuspendableQuery';

export const FEATURED_CONTRIBUTORS = gql`
  query featuredContributors {
    contentfulContributorDirectory(id: "7ahVNtj2JXgPzbmAx0849j") {
      entryName
      featuredContributorsCollection {
        items {
          slug
          webDisplayName
          title
          shortBio
          contributorOrganization {
            name
            slug
          }
          headshot {
            url(
              transform: {
                format: JPG
                resizeFocus: FACE
                resizeStrategy: FILL
                width: 400
                height: 400
              }
            )
          }
          practiceAreasCollection {
            items {
              sys {
                id
              }
              slug
              name
            }
          }
        }
      }
    }
  }
`;

export const CONTRIBUTOR_TOTAL = gql`
  query contributorTotal {
    contentfulContributorCollection(
      where: { displayInContributorDirectory: true }
    ) {
      total
    }
  }
`;
export function useFeaturedContributors() {
  const { data } = useSuspendableQuery(FEATURED_CONTRIBUTORS);

  const featuredContributors = React.useMemo(() => {
    return (
      data?.contentfulContributorDirectory?.featuredContributorsCollection
        ?.items || []
    );
  }, [data]);

  return featuredContributors;
}
export function useContributorsTotal() {
  const { data } = useSuspendableQuery(CONTRIBUTOR_TOTAL);
  return data?.contentfulContributorCollection?.total || '-';
}
