import React from 'react';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

export const FormLabel = ({
  className = '',
  hint = '',
  children,
  label,
  required,
  readonly = false,
  ...props
}) => {
  return (
    <StyledFormLabel className={className} data-readonly={readonly} {...props}>
      <span className={`label-text ${className}`}>
        {label}
        {!required ? (
          <span className={`label-required ${className}`}>optional</span>
        ) : null}
      </span>
      {hint ? <span className={`hint-text ${className}`}>{hint}</span> : null}
      {children}
    </StyledFormLabel>
  );
};

const StyledFormLabel = styled.label`
  .label-text {
    margin-bottom: 0.72em;
    display: inline-block;
  }
  .label-required {
    float: right;
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: bold;
    margin-top: 0.2em;
    color: ${CEB_COLOR('HAVELOCK_BLUE')};
  }
  &[data-readonly] {
    &:hover {
      cursor: default;
    }
  }
`;
