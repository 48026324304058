import React, { useMemo } from 'react';
import { convertDomToReactElements } from './convertDomToReactElements';
import removeChildren from 'util/removeChildren';

export const ElementRenderer = ({ content, afterUpdate, ...props }) => {
  const elementRef = React.useRef();

  React.useEffect(() => {
    removeChildren(elementRef.current);
    elementRef.current.appendChild(content);
    afterUpdate && afterUpdate();
  }, [content, afterUpdate]);

  return <div ref={elementRef} {...props} />;
};

export const ReactElementRenderer = React.forwardRef(
  ({ content, afterUpdate, transformer, ...props }, ref) => {
    React.useLayoutEffect(() => {
      afterUpdate && afterUpdate();
    }, [content, afterUpdate]);

    const Component = useMemo(
      () => convertDomToReactElements(content, transformer),
      [content, transformer],
    );

    return (
      <div {...props} ref={ref}>
        {Component}
      </div>
    );
  },
);

ReactElementRenderer.displayName = 'ReactElementRenderer';
