import React from 'react';

const HowTo = ({ stroke = '#343434' }) => (
  <svg width="24px" height="25px" viewBox="0 0 24 25">
    <g
      id="----Know-How"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinejoin="round"
    >
      <g
        id="Know-How-Doc-Type-Icons"
        transform="translate(-4.000000, -2.000000)"
        stroke={stroke}
      >
        <g id="Group-28" transform="translate(1.000000, 0.000000)">
          <g id="icon-howto" transform="translate(3.000000, 3.000000)">
            <polygon
              id="Stroke-7172"
              points="3.3281 23.4805 0.5001 20.6525 18.1781 2.9745 21.0061 5.8025"
            />
            <polyline
              id="Stroke-7173"
              points="21.0059 5.8027 23.4809 14.6417 22.4199 15.7027 16.7769 10.0307"
            />
            <polyline
              id="Stroke-7174"
              points="18.1777 2.9746 9.3387 0.4996 8.2787 1.5606 13.9337 7.2176"
            />
            <path d="M4.541,19.5249 L5.916,20.8919" id="Stroke-7175" />
            <path d="M6.6621,17.4043 L8.0391,18.7693" id="Stroke-7176" />
            <path d="M8.7832,15.2832 L10.1592,16.6492" id="Stroke-7177" />
            <path d="M10.9043,13.1621 L12.2823,14.5261" id="Stroke-7178" />
            <path d="M13.0254,11.04 L14.3954,12.414" id="Stroke-7179" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default HowTo;
