import { gql } from '@apollo/client';

const CreditsEarned = gql`
  fragment CreditsEarned on User {
    barNumber
    cleComplianceDeadline {
      reportingDate
      periodStartDate
      periodEndDate
    }
    currentPeriodCredits: cleCourseCredits {
      salesforceId
      courseName
      totalCreditHours
      completedDate
      creditAreaHours {
        creditHours
        creditAreaName
      }
      specializationHours {
        creditHours
        category
        subcategory
      }
    }
    previousPeriodsCredits: cleCourseCredits(compliancePeriod: PREVIOUS) {
      salesforceId
      courseName
      totalCreditHours
      completedDate
      creditAreaHours {
        creditHours
        creditAreaName
      }
      specializationHours {
        creditHours
        category
        subcategory
      }
    }
  }
`;

const LearningProgress = gql`
  fragment LearningProgress on User {
    cleCreditRequirement
    cleCreditsEarnedFromCeb
    cleComplianceDeadline {
      reportingDate
      periodStartDate
      periodEndDate
    }
  }
`;

const UserPracticeAreas = gql`
  fragment UserPracticeAreas on User {
    secondaryPracticeAreas {
      selected
      selectedContentfulIds
    }
  }
`;

const ContentfulPracticeAreas = gql`
  fragment ContentfulPracticeAreas on ContentfulPracticeArea {
    name
    practiceAreaSubtopicsCollection {
      items {
        sys {
          id
        }
        name
        slug
        linkedFrom {
          currentAwarenessCollection {
            total
          }
        }
      }
    }
  }
`;

const UserFragments = {
  CreditsEarned,
  LearningProgress,
  UserPracticeAreas,
  ContentfulPracticeAreas,
};

export default UserFragments;
