import React from 'react';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import styled from 'styled-components';

export function ContributionSection() {
  return (
    <StyledContributionSection>
      <header>
        <h3>Ways you can Contribute to CEB</h3>
      </header>
      <div>
        <StyledContributionCard>
          <header>
            <div>
              <img src="/images/contributor/icon-daily-news.svg" alt="" />
            </div>
            <h4>DailyNews Articles</h4>
          </header>
          <div>
            <p>
              CEB’s legal news service publishes pressing news and analysis by
              subject matter experts and thought leaders in California’s legal
              community.  
            </p>
            <p>
              As a DailyNews Contributor, you would research and author articles
              about the latest legal news in your practice area.{' '}
              <a
                href="https://research.ceb.com/news"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more about DailyNews and check out articles
              </a>
              .
            </p>
          </div>
        </StyledContributionCard>
        <StyledContributionCard>
          <header>
            <div>
              <img src="/images/contributor/icon-practitioner.svg" alt="" />
            </div>
            <h4>Practitioner</h4>
          </header>
          <div>
            <p>
              CEB’s legal practical guidance tool is the only California-focused
              know-how solution on the market. Practitioner Workflows are
              designed and assembled to mirror the way you practice law, with
              how-to guides, strategy notes, charts and checklists, and standard
              documents.  
            </p>
            <p>
              As a Practitioner Contributor, you would create and update
              in-depth resources on how to best practice law in your practice
              area.{' '}
              <a
                href="https://www.ceb.com/products/practitioner/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more about Practitioner
              </a>
              .
            </p>
          </div>
        </StyledContributionCard>
        <StyledContributionCard>
          <header>
            <div>
              <img src="/images/contributor/icon-guidebooks.svg" alt="" />
            </div>
            <h4>Publications</h4>
          </header>
          <div>
            <p>
              CEB publishes nearly 150 practice guides covering a broad array of
              California legal topics and providing attorneys with in-depth
              legal analysis. CEB’s publications are renowned for their
              authoritative content and are regularly cited by California
              courts.  
            </p>
            <p>
              As a Publications Contributor, you would draft or review
              individual chapters or entire treatises.{' '}
              <a
                href="https://store.ceb.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more about our Publications
              </a>
              .
            </p>
          </div>
        </StyledContributionCard>
        <StyledContributionCard>
          <header>
            <div>
              <img
                src="/images/contributor/icon-webinars.svg"
                width="170"
                alt=""
              />
            </div>
            <h4>Learning Programs</h4>
          </header>
          <div>
            <p>
              CEB programs emphasize practical strategies to navigate
              California's complex legal landscape through on-demand legal
              education videos, providing tips, tools, checklists, templates,
              and tactics intended to enhance the practice of law across the
              state.  
            </p>
            <p>
              As a CEB Speaker, you would share your expertise with a statewide
              audience, while promoting your work, improving your public
              speaking skills, and boosting your professional recognition.{' '}
              <a
                href="https://www.ceb.com/products/ceb-mcle/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more about our Learning Programs
              </a>
              .
            </p>
          </div>
        </StyledContributionCard>
      </div>
    </StyledContributionSection>
  );
}

export const StyledContributionSection = styled.section`
  margin-bottom: ${76 - 69}px;
  > header {
    margin-bottom: 45px;
  }
  > div {
    display: flex;
    flex-wrap: wrap;
    > div {
      flex: 1 1 auto;
      max-width: calc(50% - 30px);
    }
  }
`;

export const StyledContributionCard = styled.div`
  margin-bottom: 50px;
  &:nth-child(even) {
    margin-left: 60px;
  }
  header {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    min-height: 113px;
    div {
      flex: 0 1 auto;
      &:first-child {
        margin-right: 40px;
      }
    }
  }
  img {
    display: inline;
  }
  h4 {
    color: ${CEB_COLOR('CEB_HEADER_BLUE')};
    font-family: BasisGrotesque;
    font-size: 48px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
  }
  p {
    font-family: BasisGrotesque;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    margin-bottom: 0.5em;
  }
  a,
  a:visited {
    color: ${CEB_COLOR('BLUE_RIBBON')};
  }
`;
