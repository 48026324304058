import { gql } from '@apollo/client';
import TitledPage from 'components/common/TitledPage';
import useSuspendableQuery from 'hooks/useSuspendableQuery';
import React, { useMemo } from 'react';
import { useLocation, useParams } from 'react-router';
import styled from 'styled-components';
import { CEBContributorsHeader } from '../CEBContributorsHeader';
import { ContentfulContent } from 'components/common/ContentfulContent';
import { PracticeAreaTags } from 'components/daily-news/PracticeAreaTags';
import { StyledPostContent } from 'components/posts/PostContent';
import Firm from 'components/common/icons/Firm';
import { NavLink } from 'react-router-dom';
import { useCoursePracticeAreaPathBuilder } from 'components/cle-library/landing/useCoursePracticeAreaPathBuilder';
import { CLEContributorProfileContentBy } from './CLEContributorProfileContentBy';
import { truncateToNearestWord } from 'util/truncateToNearestWord';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

const GET_CONTRIBUTOR = gql`
  query getContributor($slug: String!) {
    contributor(slug: $slug) {
      slug
      practiceAreas {
        contentfulId
        slug
        name
      }
      firstName
      lastName
      webDisplayName
      title
      judge
      region
      counties
      email
      phoneNumber
      linkedInUrl
      streetAddress
      city
      state
      zipCode
      bio
      headshotUrl
      contributorOrganization {
        name
        slug
        website
        contributorCount
      }
      currentAwarenessItemsCount
      knowHowDocumentItemsCount
      courseItemsCount
      publicationContributionBooks {
        name
        onlawProProductCode
        primaryPracticeAreaLibrary {
          name
          slug
        }
        originalAuthor
        updateAuthor
        publicationContributionChapters {
          name
          onlawId
          originalAuthor
          updateAuthor
        }
      }
    }
  }
`;

const truncate = truncateToNearestWord();

export default function CLEContributorProfile() {
  const location = useLocation();
  const { slug } = useParams();
  const {
    data: { contributor },
  } = useSuspendableQuery(GET_CONTRIBUTOR, {
    variables: { slug },
  });

  const title = useMemo(
    () => `${contributor.webDisplayName} - CEB Contributors`,
    [contributor],
  );

  const practiceAreaPathBuilder = useCoursePracticeAreaPathBuilder('/');

  return (
    <TitledPage
      title={title}
      meta={{
        'og:title': title,
        'og:image': contributor.headshotUrl,
        'og:description': truncate(documentToPlainTextString(contributor.bio)),
      }}
      link={`https://contributors.ceb.com/contributor/${slug}`}
    >
      <div className="public-cle container px-3 mx-auto xl:px-0">
        <CEBContributorsHeader className="alternative" />
        <StyledCLEContributorProfile>
          <StyledContributorContent>
            <h3>{contributor.webDisplayName}</h3>
            <h4>{contributor.title}</h4>
            {contributor.bio && (
              <StyledContentfulContent>
                <ContentfulContent document={contributor.bio} />
              </StyledContentfulContent>
            )}
            {contributor.practiceAreas?.length > 0 && (
              <div>
                <h5>Practice Areas</h5>
                <PracticeAreaTags
                  practiceAreas={contributor.practiceAreas}
                  pathBuilder={practiceAreaPathBuilder}
                />
              </div>
            )}
            <ul>
              <li>
                <h5>CEB Region</h5>
                <ul>
                  <li>
                    <NavLink to={`/?regions=${contributor.region}`}>
                      {contributor.region}
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <h5>Counties of Practice</h5>
                <ul>
                  {contributor.counties?.map(county => (
                    <li key={`contributor-counties-${county}`}>
                      <NavLink to="/">{county}</NavLink>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </StyledContributorContent>
          <StyledContributorSidebar>
            <img
              src={contributor.headshotUrl}
              alt={contributor.webDisplayName}
            />
            <ul>
              {contributor.email && (
                <li>
                  <i className="fas fa-envelope" />
                  <a href={`mailto:${contributor.email}`}>Send Email</a>
                </li>
              )}
              {contributor.phoneNumber && (
                <li>
                  <i className="fas fa-phone-alt" />
                  <a href={`tel:${contributor.phoneNumber}`}>
                    {contributor.phoneNumber}
                  </a>
                </li>
              )}
              {contributor.linkedInUrl && (
                <li>
                  <i className="fab fa-linkedin-in" />
                  <a href={contributor.linkedInUrl}>LinkedIn Profile</a>
                </li>
              )}
              {contributor.streetAddress && (
                <li>
                  <i className="fas fa-map-marker-alt" />
                  <div>
                    {contributor.streetAddress} <br />
                    {contributor.city}, {contributor.state}{' '}
                    {contributor.zipCode}
                  </div>
                </li>
              )}
            </ul>
            {contributor.contributorOrganization && (
              <StyledFirmCard>
                <Firm width={48} height={48} />
                <div>
                  <NavLink
                    to={`/organization/${
                      contributor.contributorOrganization.slug
                    }`}
                    state={{ fromTitle: title, from: location }}
                  >
                    {contributor.contributorOrganization.name}
                  </NavLink>
                  <p>
                    {contributor.contributorOrganization.contributorCount} CEB
                    Contributors{' '}
                    <NavLink
                      to={`/organization/${
                        contributor.contributorOrganization.slug
                      }`}
                      state={{ fromTitle: title, from: location }}
                    >
                      View
                    </NavLink>
                  </p>
                </div>
              </StyledFirmCard>
            )}
          </StyledContributorSidebar>
        </StyledCLEContributorProfile>
      </div>
      <CLEContributorProfileContentBy contributor={contributor} />
    </TitledPage>
  );
}

const StyledFirmCard = styled.div`
  display: flex;
  margin-top: 27px;
  svg {
    margin-right: 10px;
  }
  > div {
    font-family: 'BasisGrotesque', sans-serif;
    font-size: 13px;
    margin-top: 5px;
    > a {
      font-size: 16px;
      font-weight: 500;
      font-style: italic;
      color: #185cad;
    }
  }
`;

const StyledContributorContent = styled.div`
  flex: 1;
  > h3 {
    margin: 0 0 24px 0px;
    font-family: 'LyonDisplay', serif;
    font-size: 48px;
    line-height: 1.13;
    color: #111111;
  }

  > h4 {
    margin: 0 0 18px 0;
    font-family: 'BasisGrotesque', sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.36;
  }

  > div > h5 {
    font-family: 'BasisGrotesque', sans-serif;
    font-size: 16px;
    font-weight: 900;
    margin-bottom: 12px;
    text-transform: uppercase;
  }

  > ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
    margin-top: 32px;
    width: 446px;
    > li {
      font-family: 'BasisGrotesque', sans-serif;
      font-size: 15px;
      > h5 {
        font-weight: normal;
      }

      > ul li a {
        color: #185cad;
        font-weight: bold;
      }
    }
  }
`;

const StyledContributorSidebar = styled.div`
  padding: 22px 20px 20px 20px;
  margin-top: 20px;
  @media screen and (min-width: 1166px) {
    margin-left: 81px;
    margin-top: 0;
    max-width: 340px;
    min-width: 340px;
  }

  > img {
    width: 200px;
    height: 200px;
    border-radius: 100px;
    margin-bottom: 24px;
  }

  > ul {
    li {
      color: #676968;
      display: flex;
      font-family: 'BasisGrotesque', sans-serif;
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 10px;

      i {
        color: #ffffff;
        font-size: 13px;
        width: 24px;
        height: 24px;
        border-radius: 12px;
        padding: 5.1px 5.5px;
        margin-right: 8px;
        &.fa-envelope {
          background-color: #267c7d;
        }
        &.fa-phone-alt {
          background-color: #5a5969;
        }
        &.fa-linkedin-in {
          background-color: #1175b1;
          padding: 5.1px 7px;
        }
        &.fa-map-marker-alt {
          background-color: #6e5599;
          padding: 5px 7.5px;
        }
      }

      > a {
        color: #185cad;
      }

      > a,
      > div {
        padding-top: 2px;
      }
    }
  }
`;

const StyledCLEContributorProfile = styled.section`
  padding: 0 158px;
  padding-bottom: 96px;
  @media screen and (min-width: 1166px) {
    display: flex;
  }

  &.alternative {
    background-color: #f6fbff;
    margin: 0 -105px;
    padding: 0 263px;
  }
`;

const StyledContentfulContent = styled(StyledPostContent)`
  font-size: 17px;
  margin-bottom: 36px;
  line-height: 1.53;
  font-family: 'Zilla Slab', serif;
  > p:first-child::first-letter {
    float: none;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
  }
  p {
    margin-bottom: 12px;
  }
  ul,
  ol {
    margin-bottom: 12px;
  }
`;
