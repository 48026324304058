import React from 'react';
import { FormattedTime } from 'components/common/FormattedTime';
import { AuthorNames } from 'components/common/AuthorNames';
import { PostTools } from './PostTools';
import { AppContext } from 'components/app-context';
import styled from 'styled-components';
import { CEB_COLOR, CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';
import { SuspendableImage } from 'components/climatebrief/post/SuspendableImage';

export const PostHeader = ({ post }) => {
  const { localUser } = React.useContext(AppContext);
  return (
    <StyledPostHeader>
      {localUser?.authorized && <PostTools post={post} />}
      <h2>{post.title}</h2>
      <section>
        <AuthorNames authorsCollection={post.authorsCollection} hideIcon />
        <FormattedTime time={post.originalPublishDate} flex />
      </section>
      {post.featuredImage && post.featuredImage.url ? (
        <SuspendableImage
          src={post.featuredImage.url}
          alt=""
          className="post-image"
        />
      ) : null}
    </StyledPostHeader>
  );
};

const StyledPostHeader = styled.header`
  margin-bottom: 36px;
  section {
    color: ${CEB_COLOR_RGBA('BLACK', 0.8)};
    display: flex;
    font-size: 16px;
    font-style: italic;
    .authors {
      color: ${CEB_COLOR('CEB_HEADER_BLUE')};
      flex: 0 1 auto;
      font-family: 'BasisGrotesque', sans-serif;
      font-size: 14px;
      font-weight: bold;
      margin-right: 1em;
    }
    time {
      font-family: 'BasisGrotesque', sans-serif;
      font-size: 14px;
    }
  }
  h2 {
    color: ${CEB_COLOR('BLACK_TWO')};
    font-family: Bitter, serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1.35;
    margin-bottom: 24px;
  }
  .post-image {
    margin: 36px 0 0 0;
    max-width: 680px;
    width: 100%;
  }
`;
