import React from 'react';
import styled from 'styled-components';
import TrueCite from './icons/TrueCite';

export function TrueCiteLogo({ text = true }) {
  return (
    <StyledLogoContainer className="logo-container">
      <TrueCite width="12" />
      {text && <span className="logo-text">TrueCite&trade;</span>}
    </StyledLogoContainer>
  );
}

const StyledLogoContainer = styled.span`
  display: inline-block;
  font-family: 'BasisGrotesque', sans-serif;
  font-weight: bold;
  margin-right: 0.5em;
  svg {
    display: inline-block;
    margin: 0 0.5em;
    vertical-align: -0.1em;
    width: 0.7em;
  }
  .logo-text {
    line-height: 1.2em;
    font-size: 0.75em;
    display: inline-block;
  }
`;
