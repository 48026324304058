import React from 'react';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

export function LawAlertPlaceholder() {
  return (
    <StyledLawAlertPlaceholder>
      <i className="fas fa-gavel" />
      LAW ALERT
    </StyledLawAlertPlaceholder>
  );
}
const StyledLawAlertPlaceholder = styled.span`
  box-sizing: border-box;
  color: ${CEB_COLOR('BLACK')};
  display: inline-block;
  font-family: 'BasisGrotesque', sans-serif;
  font-size: 14px;
  font-weight: 900;
  height: 100%;
  padding-bottom: calc(66.67% - 26% - 1em);
  padding-top: 26%;
  position: relative;
  text-align: center;
  width: 100%;
  @media screen and (min-width: 320px) {
    font-size: 28px;
  }
  &:before {
    background: url('/images/law-alerts-background.png') top center no-repeat;
    background-size: contain;
    box-sizing: border-box;
    content: ' ';
    display: block;
    height: 100%;
    opacity: 0.26;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
  i {
    margin-right: 0.35em;
    transform: scale(-1, 1);
  }
`;
