import React from 'react';
import { NavigationType, useLocation, useNavigationType } from 'react-router';

export function ScrollToHere() {
  const scrollTargetRef = React.useRef();
  const location = useLocation();
  const navigationType = useNavigationType();

  const shouldScrollIntoView = React.useMemo(() => {
    // scrolls to element ref on PUSH navigation to new search params
    return !!(
      location?.search !== '' && navigationType === NavigationType.Push
    );
  }, [navigationType, location]);

  React.useLayoutEffect(() => {
    shouldScrollIntoView && scrollTargetRef.current.scrollIntoView(true);
  }, [shouldScrollIntoView, location]);

  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return <a ref={scrollTargetRef} />;
}
