import React from 'react';
import styled, { keyframes } from 'styled-components';

const blink = keyframes`
  from {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const LoadingEllipses = props => {
  return (
    <StyledLoadingEllipses>
      <span>.</span>
    </StyledLoadingEllipses>
  );
};

const StyledLoadingEllipses = styled.u`
  display: inline-block;
  text-decoration: none;
  span,
  &:before,
  &:after {
    animation: 1s ${blink} infinite;
  }
  &:before {
    opacity: 0;
  }
  span {
    animation-delay: 0.33s;
  }
  &:after {
    animation-delay: 0.66s;
  }
  &:before,
  &:after {
    display: inline-block;
    content: '.';
  }
`;
