import React from 'react';
import StyledFilterOption from 'components/common/search/sidebar/filters/StyledFilterOption';
import { FormCheckbox } from 'components/common/json_form';
import { QueryInput } from 'components/common/query-input';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import styled from 'styled-components';

export function DateFilters() {
  const resetCustomForm = ({ dateRecorded }) => ({
    dateRecorded: dateRecorded === '' ? undefined : dateRecorded,
    dateRecorded_gte: undefined,
    dateRecorded_lte: undefined,
    page: undefined,
  });

  return (
    <>
      <StyledFilterOption className="option">
        <QueryInput
          through={FormCheckbox}
          type="radio"
          name="dateRecorded"
          value=""
          placeholder="Any time"
          mutate={resetCustomForm}
        />
      </StyledFilterOption>
      <StyledFilterOption className="option">
        <QueryInput
          through={FormCheckbox}
          type="radio"
          name="dateRecorded"
          value="custom"
          mutate={resetCustomForm}
          placeholder="Custom Range"
        />
        <StyledCustomDateRange className="custom-range-fields">
          <QueryInput type="text" name="dateRecorded_gte" maxLength={4} />
          <span>to</span>
          <QueryInput type="text" name="dateRecorded_lte" maxLength={4} />
        </StyledCustomDateRange>
      </StyledFilterOption>
    </>
  );
}

const StyledCustomDateRange = styled.div`
  &.custom-range-fields {
    margin-top: 1em;
    font-size: 0.85em;

    input {
      background: #fff;
      border: solid 1px ${CEB_COLOR('QUILL_GRAY')};
      box-sizing: border-box;
      font-family: 'Zilla Slab', serif;
      font-size: 1.2em;
      padding: 5px 10px;
      width: 3.4em;
      position: static;
      margin: 0;
      height: auto;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        margin: 0;
        -webkit-appearance: none;
      }
    }
    span {
      margin: 0 0.5em;
    }
  }
`;
