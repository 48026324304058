import React from 'react';
const circle = 'var(--COLOR_DANUBE)';
const path = 'var(--COLOR_LUCKY_POINT)';

const User = ({ height = 32, width = 32, path, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 32 32" path={path} {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill={props.circle} cx={16} cy={16} r={16} />
      <path
        d="M25.433 23.079c-.873-.716-2.582-1.25-4.291-1.964-2.037-.848-2.516-1.143-2.516-1.143l-.02-1.954s.759-.586.998-2.425c.476.14.977-.71 1.006-1.158.024-.43-.066-1.628-.648-1.508.116-.901.203-1.711.162-2.14-.15-1.571-1.696-3.225-4.075-3.225-2.371 0-3.92 1.654-4.072 3.225-.04.429.045 1.239.163 2.14-.584-.12-.673 1.078-.646 1.508.027.448.527 1.298 1.004 1.158.236 1.84 1 2.425 1 2.425l-.021 1.954s-.481.295-2.515 1.143c-1.751.73-3.508 1.272-4.358 2.013A11.723 11.723 0 0 1 4.187 16c0-6.514 5.3-11.813 11.813-11.813 6.514 0 11.812 5.299 11.812 11.813 0 2.656-.892 5.103-2.38 7.079M16 2.499C8.544 2.5 2.5 8.544 2.5 16c0 3.458 1.31 6.6 3.447 8.99C8.42 27.752 12.002 29.5 16 29.5c4.048 0 7.67-1.79 10.145-4.611A13.44 13.44 0 0 0 29.5 16c0-7.457-6.045-13.5-13.5-13.5"
        fill={path}
      />
    </g>
  </svg>
);

User.defaultProps = {
  circle,
  path,
};

export default User;
