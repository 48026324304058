import React from 'react';
import styled from 'styled-components';
import { ContributorRequirements } from './ContributorRequirements';
import { CEB_COLOR, CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';
import { StyledTag } from 'components/daily-news/PracticeAreaTags';

export function ContributorBenefits() {
  return (
    <StyledContributorBenefits>
      <div>
        <h2>Join the Community!</h2>
        <h3>Contributor Benefits</h3>
        <StyledBenefitsHeaderPara>
          As a CEB Contributor, you also will receive access to California’s
          premier legal information platform:
        </StyledBenefitsHeaderPara>
        <StyledProfileCard>
          <div>
            <img src="/images/contributor/icon-profile.svg" width="80" alt="" />
          </div>
          <div>
            <h5>Your profile on CEB</h5>
            <p>
              Great for CV building, sharing on social media, and marketing your
              expertise
            </p>
          </div>
        </StyledProfileCard>
        <StyledCoursePassCard>
          <div>
            <img
              src="/images/subscriptions/logos/coursepass.svg"
              width={210}
              alt=""
            />
            <StyledTag as="span">6-hour</StyledTag>
          </div>
          <p>6 hours of programming from our library of 700+ CLE programs</p>
          <em>To be used within 12 months</em>
        </StyledCoursePassCard>
        <StyledLawBookCard>
          <div>
            <img src="/images/contributor/law-books.svg" alt="" />
          </div>
          <div>
            <h5>12-month access</h5>
            <p>to a single digital CEB Practice Guide</p>
          </div>
        </StyledLawBookCard>
      </div>
      <div>
        <StyledHeaderPara>
          Shape California’s legal discourse with your subject matter expertise;
          build your CV through demonstrated understanding and participation in
          key California legal issues; promote your practice as one that
          provides thought leadership in your market.
        </StyledHeaderPara>
        <ContributorRequirements />
      </div>
    </StyledContributorBenefits>
  );
}

const StyledHeaderPara = styled.p`
  font-family: 'Zilla Slab', serif;
  font-size: 21px;
  line-height: 1.52;
  margin-top: -10px;
`;

const StyledBenefitsHeaderPara = styled(StyledHeaderPara)`
  font-family: 'Zilla Slab', serif;
  font-size: 17px;
  line-height: 1.53;
  margin-right: 20px;
  margin-bottom: 24px;
`;

const StyledContributorBenefits = styled.section`
  display: flex;
  margin-bottom: 0;
  > div {
    flex: 1 1 auto;

    + div {
      flex: 0 1 auto;
      margin-left: 66px;
      max-width: 500px;
    }

    > h2 {
      font-family: LyonDisplay;
      font-size: 64px;
      font-weight: normal;
      line-height: 1.09;
      display: inline-block;
      margin-bottom: 48px;
    }

    > h3 {
      margin-bottom: 17px;
    }
  }
`;

const StyledBenefitCard = styled.div`
  border-radius: 4px;
  border: solid 1px ${CEB_COLOR('MERCURY')};
  margin: 24px 0;
  padding: 26px 36px;
  p {
    font-family: BasisGrotesque;
    font-size: 18px;
    line-height: 1.33;
    margin-right: 0;
  }
  h5 {
    font-family: BasisGrotesque;
    font-size: 23px;
    font-weight: 900;
    margin-bottom: 6px;
    line-height: 1.04;
  }
`;

const StyledProfileCard = styled(StyledBenefitCard)`
  align-items: center;
  display: flex;
  padding: 33px 36px 38px 31px;

  div {
    flex: 0 1 auto;
    &:first-child {
      min-width: 80px;
    }
    + div {
      flex: 1 1 auto;

      margin-left: 26px;
    }
  }
`;

const StyledCoursePassCard = styled(StyledBenefitCard)`
  padding: 26px 36px 28px;
  div {
    display: flex;
    margin-bottom: 13px;
  }
  img {
    margin-right: 24px;
  }
  span {
    height: 24px;
    margin-top: 4px;
    padding-top: 3px;
  }
  p {
    margin-bottom: 2px;
  }
  em {
    color: ${CEB_COLOR_RGBA('BLACK', 0.65)};
  }
`;

const StyledLawBookCard = styled(StyledBenefitCard)`
  align-items: center;
  display: flex;
  padding: 18px 36px 14px 31px;

  div {
    flex: 0 1 auto;
    &:first-child {
      min-width: 80px;
    }
    + div {
      margin-left: 26px;
    }
  }

  h5 {
    font-family: BasisGrotesque;
    font-size: 23px;
    font-weight: 900;
    margin-bottom: 6px;
    line-height: 1.04;
  }
`;
