import React from 'react';

export function useToggle(initial = false) {
  const [on, setShowMore] = React.useState(initial);
  const toggle = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setShowMore(on => !on);
      (event.target as HTMLElement).blur();
    },
    [],
  );
  return [on, toggle] as const;
}
