import React from 'react';
import { nodeListToArray } from 'util/nodeListToArray';

export function useSummary(ref, selector, summaryLength) {
  const [summary, setSummary] = React.useState(undefined);
  React.useEffect(() => {
    const el = ref.current;
    if (el && el.querySelectorAll) {
      const text = nodeListToArray(el.querySelectorAll(selector))
        .map(el => el.innerText)
        .reduce((v, t) => {
          return v.length < summaryLength ? `${v} ${t}` : v;
        }, '')
        .split(' ')
        .reduce((v, t) => {
          return v.length < summaryLength ? `${v} ${t}` : v;
        });
      setSummary(text);
    }
  }, [ref]);
  return [summary];
}
