import React, { useMemo } from 'react';
import styled from 'styled-components';
import { CLEProgramsContributions } from './CLEProgramsContributions';
import { DailyNewsContributions } from './DailyNewsContributions';
import { MemoryTabs, MemoryTab } from './MemoryTabs';
import { PractitionerDocumentContributions } from './PractitionerDocumentContributions';
import { SecondarySourcesContributions } from './SecondarySourcesContributions';

export function CLEContributorProfileContentBy({
  contributor: {
    publicationContributionBooks,
    currentAwarenessItemsCount,
    knowHowDocumentItemsCount,
    courseItemsCount,
    webDisplayName,
    slug,
  },
}) {
  const hasContent = useMemo(
    () =>
      publicationContributionBooks?.length > 0 ||
      currentAwarenessItemsCount > 0 ||
      knowHowDocumentItemsCount > 0 ||
      courseItemsCount > 0,
    [
      publicationContributionBooks,
      currentAwarenessItemsCount,
      knowHowDocumentItemsCount,
      courseItemsCount,
    ],
  );

  return hasContent ? (
    <StyledContentBy>
      <div className="public-cle container px-3 mx-auto xl:px-0">
        <h2>Content by {webDisplayName}</h2>
        <MemoryTabs defaultTab={0}>
          {currentAwarenessItemsCount > 0 && (
            <MemoryTab content={<DailyNewsContributions slug={slug} />}>
              Daily News <span>({currentAwarenessItemsCount})</span>
            </MemoryTab>
          )}
          {publicationContributionBooks?.length > 0 && (
            <MemoryTab
              content={
                <SecondarySourcesContributions
                  publicationContributionBooks={publicationContributionBooks}
                />
              }
              slug={slug}
            >
              Secondary Sources{' '}
              <span>({publicationContributionBooks.length})</span>
            </MemoryTab>
          )}
          {knowHowDocumentItemsCount > 0 && (
            <MemoryTab
              content={<PractitionerDocumentContributions slug={slug} />}
            >
              Practitioner Documents <span>({knowHowDocumentItemsCount})</span>
            </MemoryTab>
          )}
          {courseItemsCount > 0 && (
            <MemoryTab content={<CLEProgramsContributions slug={slug} />}>
              CLE Programs <span>({courseItemsCount})</span>
            </MemoryTab>
          )}
        </MemoryTabs>
      </div>
    </StyledContentBy>
  ) : null;
}

const StyledContentBy = styled.section`
  background-color: #f6fbff;
  > div {
    margin: 0 auto;
    padding: 52px 150px;
    > h2 {
      margin: 0 0 32px;
      font-family: 'LyonDisplay', serif;
      font-size: 36px;
      line-height: 1.5;
      letter-spacing: normal;
      color: #111111;
    }
  }

  ul li button {
    font-weight: bold;
  }
`;
