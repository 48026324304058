import React from 'react';
import { useTabs } from 'components/preview-tabs/useTabs';
import { TabFilterer } from 'components/preview-tabs/TabFilterer';

export const TabContext = React.createContext();
export const TabContextProvider = props => {
  const [state, dispatch] = useTabs({ limit: 3 });

  return (
    <TabContext.Provider
      value={{
        dispatch,
      }}
    >
      {props.children}
      <TabFilterer />
    </TabContext.Provider>
  );
};
