import React from 'react';

export default function Book({
  width = '41px',
  height = '62px',
  color = '#003475',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 41 62.0001652"
      version="1.1"
    >
      <title>Contributors Book Icon</title>
      <g
        id="Contributors"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fill-rule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="About-Contributor-Profile-S-Sources"
          transform="translate(-198, -1300.9998)"
          stroke={color}
          strokeWidth="2"
        >
          <g id="Group-11" transform="translate(0, 943)">
            <g id="Group-9" transform="translate(198, 219)">
              <g id="Stroke-6630-+-Stroke-6631" transform="translate(1, 140)">
                <path
                  d="M0,19.5652174 L0,52.173913 C0,60 7.8,60 7.8,60 L39,56.0869565 L39,10.4347826 C38.9987,10.4347826 5.2,14.3478261 5.2,14.3478261 C2.3283,14.3478261 0,12.0117391 0,9.13043478 C0,3.16434783 3.5399,5.2826087 33.8,0"
                  id="Stroke-6630"
                />
                <line
                  x1="6.5"
                  y1="9.13173913"
                  x2="36.4"
                  y2="5.21869565"
                  id="Stroke-6631"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
