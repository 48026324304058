import get from './get';
import endsWith from 'lodash/fp/endsWith';
import { startsWith } from 'lodash/fp';

function isLocal(host) {
  // no "." in a domain - it's localhost or something similar
  return (
    startsWith('192.168')(host) ||
    endsWith('.local')(host) ||
    host.split('.').length === 1
  );
}

export default function set(name, value, days) {
  var domain, domainParts, date, expires, host;

  if (days) {
    date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toGMTString();
  } else {
    expires = '';
  }

  host = window.location.host;

  if (isLocal(window.location.hostname)) {
    document.cookie = name + '=' + value + expires + '; path=/';
  } else {
    // Remember the cookie on all subdomains.
    //
    // Start with trying to set cookie to the top domain.
    // (example: if user is on foo.com, try to set
    //  cookie to domain ".com")
    //
    // If the cookie will not be set, it means ".com"
    // is a top level domain and we need to
    // set the cookie to ".foo.com"
    domainParts = host.split('.');
    domainParts.shift();
    domain = '.' + domainParts.join('.');

    document.cookie =
      name + '=' + value + expires + '; path=/; domain=' + domain;

    // check if cookie was successfuly set to the given domain
    // (otherwise it was a Top-Level Domain)
    if (get(name) == null || get(name) !== value) {
      // append "." to current domain
      domain = '.' + host;
      document.cookie =
        name + '=' + value + expires + '; path=/; domain=' + domain;
    }
  }
}
