import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/fp/omit';
import styled from 'styled-components';
import { CEB_COLOR, CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';

/**
 * FormCheckbox Component
 *
 * renders "placeholder" attribute as a label
 */

export const FormCheckbox = props => {
  const inputProps = omit(['inputRef', 'handleInputChange'])(props);
  const inputRef = React.useRef();

  const onKeyUp = React.useCallback(
    e => {
      if (props.tabIndex === undefined) return;
      switch (e.keyCode) {
        case 13:
          inputRef.current.click();
          break;
        default:
          return;
      }
    },
    [props.tabIndex],
  );

  return (
    <StyledFormCheckbox
      className={props.className}
      tabIndex={props.tabIndex}
      onKeyUp={onKeyUp}
    >
      {props.total !== undefined ? (
        <span className="total">{props.total}</span>
      ) : null}
      <input {...inputProps} ref={inputRef} />
      <span>{props.placeholder}</span>
    </StyledFormCheckbox>
  );
};

const StyledFormCheckbox = styled.label`
  display: block;
  font-size: 0.85em;
  padding: 7px 0;

  input {
    appearance: none;
    background: white;
    border-radius: 0.2em;
    border: 1px solid ${CEB_COLOR('ALTO')};
    box-shadow: 0px 0.1em 0.2em rgba(0, 0, 0, 0.1) inset;
    height: 1.2em;
    width: 1.2em;
    margin: 0 0.5em 0 0;
    vertical-align: -0.2em;
    position: relative;

    &:after {
      -webkit-font-smoothing: antialiased;
      color: ${CEB_COLOR('NICE_BLUE')};
      display: inline-block;
      font-family: 'Font Awesome 5 Free';
      font-size: 0.75em;
      font-style: normal;
      font-variant: normal;
      font-weight: 900;
      text-rendering: auto;
    }

    &:indeterminate {
      &:after {
        content: '\f068';
        position: absolute;
        left: 0.33em;
        top: 0.25em;
      }
    }
    &:checked {
      &:after {
        content: '\f00c';
        position: absolute;
        left: 0.25em;
        top: 0.2em;
      }
    }
  }

  .total {
    float: right;
    font-weight: bold;
    color: ${CEB_COLOR_RGBA('BLACK', 0.6)};
    margin-left: 1em;
  }
`;

FormCheckbox.propTypes = {
  /** name of input */
  name: PropTypes.string,
  /** label for checkbox */
  placeholder: PropTypes.string,
};

FormCheckbox.defaultProps = {
  placeholder: '',
};
