import queryString from 'util/qs';

function getQueryString(location) {
  if (typeof window !== 'undefined') {
    return queryString.parse(window.location.search) || {};
  } else {
    return queryString.parse(location?.search || '') || {};
  }
}

export default getQueryString;
