import React from 'react';
import { useLocation } from 'react-router-dom';
import useDropdown from 'hooks/useDropdown';

export const BookmarkContext = React.createContext();
export const BookmarkContextProvider = props => {
  const bookmarkRef = React.useRef();
  const [bookmarkDropdown, bookmarkToggle] = useDropdown();
  const location = useLocation();

  React.useEffect(() => {
    bookmarkRef.current = undefined;
  }, [location.pathname]);
  return (
    <BookmarkContext.Provider
      value={{ bookmarkRef, bookmarkDropdown, bookmarkToggle }}
    >
      {props.children}
    </BookmarkContext.Provider>
  );
};
