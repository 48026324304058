import React from 'react';
import { useWrappedIntercom } from 'components/app/useWrappedIntercom';

export function useIntercomDefaultLauncher() {
  const intercom = useWrappedIntercom();
  React.useLayoutEffect(() => {
    intercom.update({ hideDefaultLauncher: false });

    return () => {
      intercom.update({ hideDefaultLauncher: true });
    };
  }, []);
}
