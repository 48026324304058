import { setContext } from '@apollo/client/link/context';
import { CEB_AUTH_TOKEN } from 'app-constants';
import get from 'util/cookie/get';

export const withToken = setContext((_, { headers }) => {
  const token = get(CEB_AUTH_TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});
