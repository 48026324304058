import React from 'react';
import { Helmet } from 'react-helmet-async';

export interface TitledPageProps {
  title?: string;
  children?: React.ReactNode;
  meta?: Record<string, string>;
  link?: Record<string, string>;
  defaultTitle?: string;
  titleTemplate?: string;
}

export default function TitledPage({
  title,
  children,
  meta,
  link,
  defaultTitle = 'CEB',
  titleTemplate = '%s - CEB',
  ...props
}: TitledPageProps) {
  const { metaTags, linkTags } = React.useMemo(() => {
    return {
      metaTags: !meta ? null : (
        <>
          {Object.keys(meta).map((key, index) => {
            return (
              <meta
                property={key}
                content={meta[key]}
                key={`meta-tag-${key}-${index}`}
              />
            );
          })}
        </>
      ),
      linkTags: !link ? null : (
        <>
          {Object.keys(link).map((key, index) => {
            return (
              <link
                rel={key}
                href={link[key]}
                key={`link-tag-${key}-${index}`}
              />
            );
          })}
        </>
      ),
    };
  }, [link, meta]);

  return (
    <>
      <Helmet
        defaultTitle={defaultTitle}
        titleTemplate={titleTemplate}
        title={title}
        {...props}
      >
        {metaTags}
        {linkTags}
      </Helmet>
      {children}
    </>
  );
}
