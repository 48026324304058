/* eslint-disable no-console */
import React from 'react';
import { useIntercom } from 'react-use-intercom';
import { CEB_PORTAL_INTERCOM_APP_ID } from 'app-constants';

export function useWrappedIntercom(logEvents = false) {
  const intercom = useIntercom();

  const log = React.useCallback(
    (...args) => {
      logEvents && console.log(...args);
    },
    [logEvents],
  );

  const memoizedWrapper = React.useMemo(() => {
    return {
      update: log,
      trackEvent: log,
    };
  }, [log]);

  return CEB_PORTAL_INTERCOM_APP_ID !== undefined ? intercom : memoizedWrapper;
}
