import React from 'react';
import PropTypes from 'prop-types';
import classSet from 'react-classset';
import { FormCheckbox } from '../form_checkbox';
import { InputComponent } from './input';
import { formContext } from '../index';
import { FormLabel } from '../FormLabel';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

export const FormInput = props => {
  const inputHookRef = React.useRef();
  const inputRef = props.inputRef || inputHookRef;
  const context = React.useContext(formContext);

  const [valid, setValid] = React.useState(false);

  const validate = React.useCallback(event => {
    const { name, validator } = props;
    if (validator && inputRef) {
      const value = inputRef.current.input
        ? inputRef.current.input.value
        : inputRef.current.value;
      const excessive =
        inputRef.current.maxLength !== -1
          ? value.length > inputRef.current.maxLength
          : false;
      const valid =
        typeof validator === 'function'
          ? validator(value) && !excessive
          : validator.test(value) && !excessive;
      setValid(valid);
      context && context.setFormState(name, valid);
    } else {
      setValid(true);
      context && context.setFormState(name, true);
    }
  });

  React.useEffect(validate, [props.validateOn]);

  const handleInputChange = React.useCallback(e => {
    if (props.onChange) {
      e.persist();
      props.onChange(e);
    }
    validate(e);
  });

  const classNames = Object.assign(
    {
      invalid: !valid,
    },
    props.className
      ? {
          [props.className]: true,
        }
      : {},
  );

  return (
    <StyledFormInput className={classSet(classNames)}>
      {props.type === 'checkbox' ? (
        <FormCheckbox
          {...props}
          inputRef={inputRef}
          handleInputChange={handleInputChange}
        />
      ) : props.label ? (
        <FormLabel {...props} className={classSet(classNames)}>
          <InputComponent
            {...props}
            inputRef={inputRef}
            handleInputChange={handleInputChange}
          />
        </FormLabel>
      ) : (
        <InputComponent
          {...props}
          inputRef={inputRef}
          handleInputChange={handleInputChange}
        />
      )}
    </StyledFormInput>
  );
};

const StyledFormInput = styled.div`
  margin-bottom: 5px;
  text-align: left;
  &.invalid {
    input {
      color: ${CEB_COLOR('TUSCANY')};
      border-color: ${CEB_COLOR('TUSCANY')};
    }
  }

  input,
  select {
    border: 1px solid;
    box-sizing: border-box;
    font-family: 'Zilla Slab', serif;
    font-size: inherit;
  }
  input[type='text'],
  input[type='search'],
  input[type='email'],
  input[type='password'] {
    padding: 5px 10px;
    width: 100%;
  }
`;

FormInput.propTypes = {
  /** name of input */
  name: PropTypes.string,
  /** type of form input */
  type: PropTypes.oneOf([
    'text',
    'password',
    'search',
    'email',
    'number',
    'checkbox',
  ]),
  /** placeholder text */
  placeholder: PropTypes.string,
};

FormInput.defaultProps = {
  type: 'text',
  placeholder: '',
};
