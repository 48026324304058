import { StyleConstant } from 'util/getStyleConstant';

const attitudeTypes = [
  'CAUTION',
  'CONTRAST',
  'CITED',
  'DISCUSSED',
  'NEGATIVE',
  'WARNING',
];

export default function (attitude) {
  if (attitudeTypes.includes(attitude)) {
    return `var(--ATTITUDE_COLORS_${attitude})`;
  } else {
    return 'var(--ATTITUDE_COLORS_UNKNOWN)';
  }
}
