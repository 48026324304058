import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';
import { FOCUSABLES_SELECTOR } from 'app-constants/FOCUSABLES_SELECTOR';

interface StyledModalProps {
  backgroundColor?: string;
  contentHeight?: number | string;
  contentWidth?: number | string;
  maxWidth?: string;
  maxHeight?: string;
  minWidth?: string;
  minHeight?: string;
  height?: string;
  verticalMargin?: number;
}

export interface ModalOverlayProps extends StyledModalProps {
  visible?: boolean;
  closeModal?: (event?: React.SyntheticEvent<HTMLElement>) => void;
  root?: HTMLElement;
  backgroundShade?: number;
  backgroundOpacity?: number;
  children?: React.ReactNode;
}

export const ModalOverlay = (props: ModalOverlayProps) => {
  const {
    contentHeight = 80,
    contentWidth = 80,
    maxHeight,
    minHeight,
    height,
    maxWidth = '1024px',
    minWidth = '320px',
    root = document.body,
  } = props;

  const verticalMargin =
    (100 - (Number.isInteger(contentHeight) ? (contentHeight as number) : 0)) /
    2;
  const { backgroundShade = 0, backgroundOpacity = 0.85 } = props;
  const backgroundColor = `rgba(
              ${backgroundShade},
              ${backgroundShade},
              ${backgroundShade},
              ${backgroundOpacity})`;
  const focusables = React.useRef<Array<HTMLElement>>([]);
  const overlayContainer = React.useRef<HTMLDivElement>(null);
  const overlayContent = React.useRef<HTMLDivElement>(null);

  const onClick = React.useCallback(
    (event: React.SyntheticEvent<HTMLDivElement>) => {
      event.stopPropagation();
      return props.closeModal && props.closeModal(event);
    },
    [props.closeModal],
  );

  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.keyCode) {
        switch (event.keyCode) {
          case 9: // [tab]
            event.preventDefault();
            event.stopPropagation();
            focusables.current.length && focusables.current[0].focus();
            return false;
          case 27: // [esc]
            return props.closeModal ? props.closeModal(event) : function () {};
          default:
        }
      }
    },
    [],
  );

  const stopPropagation = React.useCallback(
    (
      event:
        | React.MouseEvent<HTMLDivElement, MouseEvent>
        | React.KeyboardEvent<HTMLDivElement>,
    ) => {
      if (event) {
        event.stopPropagation(); // Stop propagation in the React event system
        event.nativeEvent.stopImmediatePropagation(); // Stop immediate propagation in the native event system
      }
    },
    [],
  );

  React.useLayoutEffect(() => {
    if (!props.visible) return;

    overlayContainer.current!.focus();

    const rootEl = document.querySelector<HTMLDivElement>('#root');
    if (rootEl) rootEl.style.overflow = 'hidden';

    return () => {
      if (rootEl) rootEl.style.overflow = '';
    };
  }, [props.visible]);

  React.useLayoutEffect(() => {
    focusables.current = overlayContent.current
      ? [
          ...overlayContent.current.querySelectorAll<HTMLElement>(
            FOCUSABLES_SELECTOR,
          ),
        ]
      : [];
  }, [overlayContent]);

  return ReactDOM.createPortal(
    <StyledModal
      backgroundColor={backgroundColor}
      contentHeight={contentHeight}
      contentWidth={contentWidth}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      minWidth={minWidth}
      minHeight={minHeight}
      height={height}
      verticalMargin={verticalMargin}
      aria-hidden={props.visible === false}
      className="overlay-container"
      data-visible={props.visible === true}
      tabIndex={0}
      role="presentation"
      ref={overlayContainer}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onScroll={stopPropagation}
    >
      <div
        className="overlay-content"
        ref={overlayContent}
        role="presentation"
        onClick={stopPropagation}
        onKeyDown={stopPropagation}
        onKeyPress={stopPropagation}
        onKeyUp={stopPropagation}
      >
        {props.children}
      </div>
    </StyledModal>,
    root,
  );
};

const StyledModal = styled.div<StyledModalProps>`
  &.overlay-container {
    display: none;
    text-align: left;
    &:focus {
      box-shadow: inset 0 0 0 2px ${CEB_COLOR_RGBA('DANUBE', 0.6)};
    }
    &[data-visible='true'] {
      background: ${({ backgroundColor }) => backgroundColor};
      bottom: 0;
      ${({ contentHeight }) =>
        contentHeight === 'auto'
          ? `
          display: flex; 
          align-items: center;
          `
          : 'display: block;'}
      height: 100vh;
      left: 0;
      overflow: auto;
      overscroll-behavior-y: contain;
      position: fixed;
      right: 0;
      top: 0;
      width: 100vw;
      z-index: 110;
    }
  }

  .overlay-content {
    background: white;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    ${({ contentHeight }) =>
      contentHeight !== 'auto' ? `height: ${contentHeight}vh;` : ''}
    margin: auto auto;
    ${({ contentHeight, verticalMargin }) =>
      contentHeight !== 'auto'
        ? `
      margin-top: ${verticalMargin}vh;
      margin-bottom: ${verticalMargin}vh;
    `
        : 'flex: 0 1 auto;'}
    width: ${({ contentWidth }) => contentWidth || 80}vw;
    max-width: ${({ maxWidth }) => maxWidth};
    min-width: ${({ minWidth }) => minWidth};
    ${({ maxHeight }) => (maxHeight ? `max-height: ${maxHeight};` : '')}
    ${({ minHeight }) => (minHeight ? `min-height: ${minHeight};` : '')}
    ${({ height, minHeight, maxHeight }) =>
      `height: ${height || maxHeight || minHeight};`}
    position: relative;
    overflow: hidden;
  }
`;

export function useModalState(defaultVisible = false) {
  const [modalVisible, setModalVisible] = React.useState(defaultVisible);
  const toggleModal = React.useCallback(() => {
    setModalVisible(v => !v);
  }, [setModalVisible]);

  const closeModal = React.useCallback(() => {
    setModalVisible(false);
  }, [setModalVisible]);

  const openModal = React.useCallback(() => {
    setModalVisible(true);
  }, [setModalVisible]);

  return React.useMemo(() => {
    return [
      modalVisible,
      {
        setModalOpen: setModalVisible,
        closeModal,
        openModal,
        toggleModal,
      },
    ];
  }, [modalVisible, setModalVisible, closeModal, openModal, toggleModal]);
}
