import React from 'react';
import { NavLink as Link, useLocation } from 'react-router-dom';
import { TabContext } from 'components/app-context/TabContextProvider';

export function TrackedLink({ children, ...props }) {
  const location = useLocation();

  const { dispatch } = React.useContext(TabContext) || {};
  const { tabState } = location.state || {};

  const handleLinkClick = React.useCallback(
    (...handlerArgs) => {
      const event = handlerArgs[0];
      if (props.disabled) {
        event.preventDefault();
        return false;
      }
      if (props.onClick) props.onClick(...handlerArgs);
      if (event.ctrlKey) return true;
      if (event.defaultPrevented) return;
      dispatch &&
        dispatch({
          type: 'MINIMIZE_TAB',
        });
      return true;
    },
    [dispatch],
  );

  return (
    <Link
      {...props}
      state={{
        ...(props?.state || {}),
        tabState,
        from: location,
      }}
      onClick={handleLinkClick}
    >
      {children}
    </Link>
  );
}
