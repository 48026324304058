import React from 'react';
import Loading from 'components/common/Loading';
import styled from 'styled-components';
import CLEContributorSearch from './CLEContributorSearch';
import { CLEContributorSearchSidebar } from './CLEContributorSearchSidebar';
import { CEBContributorCarousel } from './CEBContributorCarousel';
import { TrackedLink } from 'components/common/TrackedLink';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import { ScrollToHere } from 'components/app/ScrollToHere';
import { StyledCCAMapBackground } from '.';

const CLEContributorLanding = () => (
  <div className="public-cle container px-3 mx-auto xl:px-0">
    <StyledContributorLanding>
      <CEBContributorCarousel />
      <StyledJoinContributors>
        <div>
          <h3>Join the Community!</h3>
          <TrackedLink to="/join">
            Learn More & Become a Contributor
          </TrackedLink>
        </div>
        <div>
          <p>
            Contributors to CEB get access to CLE CoursePasses, digital
            publications, a profile right here in the CEB Contributor Directory,
            and membership in the legal community that is dedicated to
            contributing to the legal discourse and learning material that
            surrounding all practice areas of California law.
          </p>
        </div>
      </StyledJoinContributors>
      <WithSidebar>
        <CLEContributorSearchSidebar />
        <StyledContributorLandingContent>
          <ScrollToHere />
          <Loading>
            <CLEContributorSearch />
          </Loading>
        </StyledContributorLandingContent>
      </WithSidebar>
      <StyledCCAMapBackground />
    </StyledContributorLanding>
  </div>
);

const StyledJoinContributors = styled.section`
  display: flex;
  border-radius: 10px;
  background: ${CEB_COLOR('NICE_BLUE')};
  padding: 47px 50px 57px 50px;
  color: white;
  margin: 100px auto;
  max-width: 1166px;
  div {
    flex: 0 1 auto;
    &:first-child {
      white-space: nowrap;
      margin-right: 58px;
    }
  }
  a,
  a:visited {
    border-radius: 5px;
    border: solid 2px white;
    color: white;
    display: inline-block;
    font-family: BasisGrotesque;
    font-size: 16px;
    font-weight: bold;
    padding: 4px 29px;
    text-align: center;
  }
  h3 {
    font-family: LyonDisplay;
    font-size: 38px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.84;
    margin-bottom: 8px;
  }
  p {
    font-family: 'Zilla Slab', serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    margin-right: 18px;
    margin-top: 20px;
  }
`;

const WithSidebar = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'sidebar main';
  height: 100%;
  width: 100%;
`;

const StyledContributorLanding = styled.div``;
const StyledContributorLandingContent = styled.section`
  margin-left: 51px;
  margin-bottom: 96px;
`;

export default CLEContributorLanding;
