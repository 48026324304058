import React from 'react';

export function usePrintHandlers(beforePrint, afterPrint) {
  const [isPrinting, setPrinting] = React.useState();
  React.useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.matchMedia) {
        var mediaQueryList = window.matchMedia('print');
        var mqListener = function(mql) {
          if (mql.matches) {
            beforePrint && beforePrint();
            setPrinting(true);
          } else {
            afterPrint && afterPrint();
            setPrinting(false);
          }
        };
        mediaQueryList.addListener(mqListener);
      }
      window.addEventListener('beforeprint', beforePrint);
      window.addEventListener('afterprint', afterPrint);
      return () => {
        if (window.matchMedia) {
          mediaQueryList.removeListener(mqListener);
        }
        window.removeEventListener('beforeprint', beforePrint);
        window.removeEventListener('afterprint', afterPrint);
      };
    }
  });
  return [isPrinting];
}
