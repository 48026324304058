import React from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/fp/range';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
import { CEB_COLOR, CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';

const windowrange = (width, index, max) => {
  const offset = Math.ceil(width / 2);

  const [start, end] =
    max <= width
      ? [0, max]
      : index <= offset
      ? [0, width]
      : index + offset >= max
      ? [max - width, max]
      : [index - offset, index + offset];

  return range(start, end).map(value => value + 1);
};

const PaginateItem = props => (
  <StyledPaginateItem className={props.className}>
    {props.children}
  </StyledPaginateItem>
);

const StyledPaginateItem = styled.li`
  display: inline-block;
  border: 1px solid ${CEB_COLOR('WILD_SAND')};
  font-weight: bold;
  font-size: 16px;
  background: #fff;
  &.active {
    background: ${CEB_COLOR('LUCKY_POINT')};
    border-color: transparent;
    span {
      color: #fff;
    }
  }
  a,
  span {
    color: inherit;
    padding: 0.5em 0.75em;
    display: block;
    text-decoration: none;
  }
  a:hover {
    background: ${CEB_COLOR_RGBA('LUCKY_POINT', 0.2)};
  }
  span {
    color: ${CEB_COLOR('ALTO')};
  }
`;

const PageLink = props => (
  <PaginateItem className={props.active ? 'active' : null}>
    {props.active ? (
      <span>{props.page.toLocaleString()}</span>
    ) : (
      <Link to={props.href(props.page)}>
        {props.children || props.page.toLocaleString()}
      </Link>
    )}
  </PaginateItem>
);

export const Paginate = props => {
  const win = windowrange(props.range, props.current, props.pages);
  if (props.pages === 0) {
    return null;
  }
  return (
    <StyledPagination className="pagination">
      {props.current > 1 ? (
        <PageLink {...props} page={props.current - 1}>
          &laquo;
        </PageLink>
      ) : (
        <PaginateItem>
          <span>&laquo;</span>
        </PaginateItem>
      )}
      {win.includes(1) ? null : (
        <React.Fragment>
          <PageLink {...props} page={1} />
          <PaginateItem>
            <span>...</span>
          </PaginateItem>
        </React.Fragment>
      )}
      {win.map(page => (
        <PageLink
          active={page === props.current}
          key={page}
          {...props}
          page={page}
        />
      ))}
      {win.includes(props.pages) ? null : (
        <React.Fragment>
          <PaginateItem>
            <span>...</span>
          </PaginateItem>
          <PageLink {...props} page={props.pages} />
        </React.Fragment>
      )}
      {props.current < props.pages ? (
        <PageLink {...props} page={props.current + 1}>
          &raquo;
        </PageLink>
      ) : (
        <PaginateItem>
          <span>&raquo;</span>
        </PaginateItem>
      )}
    </StyledPagination>
  );
};

const StyledPagination = styled.ol`
  list-style-type: none;
  font-family: 'Zilla Slab', serif;
`;

Paginate.propTypes = {
  href: PropTypes.func.isRequired,
  current: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  range: PropTypes.number.isRequired,
};

Paginate.defaultProps = {
  range: 10,
};
