import { basePaths } from 'app-constants';

let prefix;

export const setCourseUrlPrefix = slug => {
  prefix = slug;
};

export default function courseUrl(slug) {
  return ['/', prefix, basePaths.courses.replace(/^\//, ''), slug]
    .filter(Boolean)
    .join('/')
    .replace(/\/+/g, '/');
}

export function coursePlayUrl(slug) {
  return [courseUrl(slug), 'play'].join('/').replace(/\/+/g, '/');
}

export function courseEvaluateUrl(slug) {
  return [courseUrl(slug), 'evaluate'].join('/').replace(/\/+/g, '/');
}

export function rawCoursePlayUrl(slug) {
  return ['/raw', courseUrl(slug), 'play'].join('/').replace(/\/+/g, '/');
}

export const courseSearchUrl = [courseUrl(), 'search'].join('/');
