import React from 'react';
import {
  Navigate,
  NavigateProps,
  useLocation,
  useNavigate,
} from 'react-router';

export interface RedirectWithStateProps extends NavigateProps {}

export function RedirectWithState({
  to,
  replace,
  state,
}: RedirectWithStateProps) {
  const { state: locationState } = useLocation();
  return (
    <Navigate
      to={to}
      state={{ ...(locationState as any), ...state }}
      replace={replace}
    />
  );
}

export function useNavigateWithState() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const stateRef = React.useRef<any>(state);

  React.useEffect(() => {
    stateRef.current = state;
  }, [state]);

  return React.useCallback(
    (
      to: NavigateProps['to'],
      {
        state: navigateState,
        replace,
      }: {
        state?: NavigateProps['state'];
        replace?: NavigateProps['replace'];
      } = {},
    ) => {
      navigate(to, {
        state: { ...stateRef.current, ...navigateState },
        replace,
      });
    },
    [navigate],
  );
}
