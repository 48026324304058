import React from 'react';
import styled from 'styled-components';
import { gql } from '@apollo/client';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import { RawContentfulSummary } from 'components/common/ContentfulContent';
import { FormattedTime } from 'components/common/FormattedTime';
import { BREAKPOINT_HANDHELD } from 'styles/constants';
import { useContributionQuery } from './useContributionQuery';
import { PracticeAreaTags } from 'components/daily-news/PracticeAreaTags';
import { usePracticeAreaRootSlugs } from 'components/cle-library/usePracticeAreaRootSlugs';

const GET_CLE_PROGRAMS_CONTRIBUTIONS = gql`
  query getCleProgramsContributions($slug: String!) {
    contentfulContributorCollection(where: { slug: $slug }, limit: 1) {
      items {
        linkedFrom {
          courseCollection(limit: 50) {
            items {
              active
              title
              slug
              dateRecorded
              featureImage {
                url
              }
              totalCredits
              specialRequirementsCredits
              specializationCredits
              longDescription {
                json
              }
              speakersCollection(limit: 1) {
                items {
                  webDisplayName
                }
              }
              practiceAreasCollection(limit: 10) {
                items {
                  sys {
                    id
                  }
                  name
                  slug
                }
              }
            }
          }
        }
      }
    }
  }
`;

export function CLEProgramsContributions({ slug }) {
  const items = useContributionQuery(
    GET_CLE_PROGRAMS_CONTRIBUTIONS,
    slug,
    'courseCollection',
  );

  const rootSlugs = usePracticeAreaRootSlugs();

  return (
    <StyledCLEProgramContributionList>
      {items
        .filter(item => item.active)
        .map((item, i) => (
          <CLEProgramContribution
            item={item}
            key={`courseCollection-${i}`}
            rootSlugs={rootSlugs}
          />
        ))}
    </StyledCLEProgramContributionList>
  );
}

const practiceAreaPathbuilder = area => {
  return `/?practiceAreas=${area.sys.id}`;
};

function CLEProgramContribution({ item, rootSlugs }) {
  const {
    title,
    subtitle,
    featureImage,
    dateRecorded,
    longDescription,
    slug,
    speakersCollection,
  } = item;
  const practiceAreas = item.practiceAreasCollection.items.filter(area =>
    rootSlugs.includes(area.slug),
  );
  return (
    <StyledCLEProgramContribution>
      <section className="card-image">
        <a href={`https://learning.ceb.com/course/${slug}`}>
          <img src={featureImage?.url} alt="" />
        </a>
      </section>
      <section className="card-body">
        <section className="card-body-title">
          <h3>
            <a href={`https://learning.ceb.com/course/${slug}`}>{title}</a>
          </h3>
          {subtitle && <h4>{subtitle}</h4>}
        </section>
        <section className="card-body-meta">
          <span className="card-body-speaker-name">
            {speakersCollection?.items[0]?.webDisplayName}
          </span>
          <span className="card-body-recorded-date">
            <FormattedTime time={dateRecorded} format="MMMM D, YYYY" flex />
          </span>
        </section>
        <section className="card-body-summary">
          <RawContentfulSummary content={longDescription} summaryLength={200} />
        </section>
        {practiceAreas && (
          <PracticeAreaTags
            practiceAreas={practiceAreas}
            pathBuilder={practiceAreaPathbuilder}
          />
        )}
      </section>
    </StyledCLEProgramContribution>
  );
}

const StyledCLEProgramContributionList = styled.ul`
  margin-top: 48px;
`;

const StyledCLEProgramContribution = styled.li.attrs(({ breakpoint }) => ({
  breakpoint: breakpoint || BREAKPOINT_HANDHELD,
}))`
  display: flex;
  gap: 25px;
  margin-bottom: 48px;
  .card-image {
    @media screen and (min-width: ${({ breakpoint }) => breakpoint}) {
      flex: 0 1 auto;
      max-width: 279px;
      min-width: 279px;
    }
  }
  .card-body {
    flex: 1 1 auto;
    max-width: calc(100% - 279px);
    margin-bottom: 15px;
    margin-right: 14px;
    .card-body-meta {
      color: ${CEB_COLOR('CEB_HEADER_BLUE')};
      font-family: 'BasisGrotesque', sans-serif;
      font-size: 14px;
      font-style: italic;
    }
    .card-body-speaker-name {
      font-weight: bold;
      margin-right: 0.5em;
    }
  }
  .card-body-title {
    display: block;
    @media screen and (min-width: ${({ breakpoint }) => breakpoint}) {
      display: flex;
      flex-direction: column-reverse;
    }
    h3 {
      color: ${CEB_COLOR('CEB_HEADER_BLUE')};
      display: block;
      flex: 1 1 auto;
      font-family: 'LyonDisplay', serif;
      font-size: 24px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      line-height: 1.25;
      margin-top: 4px;
      margin-bottom: 8px;
      a {
        color: ${CEB_COLOR('CEB_HEADER_BLUE')};
      }
    }
  }
  .card-body-summary {
    font-family: 'Zilla Slab', serif;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    line-height: 1.6;
    margin-bottom: 7px;
  }
`;
