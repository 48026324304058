import React from 'react';
import styled from 'styled-components';
import { CEB_COLOR, CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';
import { TrackedLink } from 'components/common/TrackedLink';
import { PracticeAreaTags } from 'components/daily-news/PracticeAreaTags';
import { useCoursePracticeAreaPathBuilder } from 'components/cle-library/landing/useCoursePracticeAreaPathBuilder';
import { useLocation } from 'react-router';

export function FeaturedContributorSlide({ contributor }) {
  const practiceAreaPathBuilder = useCoursePracticeAreaPathBuilder('/');
  const location = useLocation();
  return (
    <StyledFeaturedContributor>
      <div>
        <TrackedLink to={`/contributor/${contributor.slug}`}>
          <img
            src={contributor.headshot.url}
            alt={`${contributor.webDisplayName} headshot`}
          />
        </TrackedLink>
      </div>
      <StyledFeaturedBio>
        <header>
          <h5>Featured Contributor</h5>
          <h3>
            <TrackedLink to={`/contributor/${contributor.slug}`}>
              {contributor.webDisplayName}
            </TrackedLink>
          </h3>
          {contributor.contributorOrganization && (
            <h4>
              {contributor.title},{' '}
              <TrackedLink
                to={`/organization/${contributor.contributorOrganization.slug}`}
                state={{ from: location }}
              >
                {contributor.contributorOrganization.name}
              </TrackedLink>
            </h4>
          )}
        </header>
        <div>
          <p>{contributor.shortBio}</p>
          <PracticeAreaTags
            practiceAreas={contributor.practiceAreasCollection?.items}
            pathBuilder={practiceAreaPathBuilder}
          />
        </div>
      </StyledFeaturedBio>
    </StyledFeaturedContributor>
  );
}

const StyledFeaturedContributor = styled.div`
  display: flex;
  background: rgba(255, 255, 255, 0.5);
  img {
    background: ${CEB_COLOR_RGBA('BLUE_RIBBON', 0.15)};
    border-radius: 100%;
    height: auto;
    overflow: hidden;
    width: 320px;
    line-height: 320px;
  }
  > div {
    flex: 0 1 auto;
    + div {
      margin-left: 44px;
    }
  }
  header {
    a,
    a:visited {
      color: ${CEB_COLOR('NICE_BLUE')};
    }
  }
`;

const StyledFeaturedBio = styled.div`
  padding: 25px 0;
  h5 {
    color: ${CEB_COLOR('DARK_GREY_BLUE')};
    font-family: BasisGrotesque;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 6px;
    text-transform: uppercase;
  }
  h3 {
    font-family: LyonDisplay;
    font-size: 40px;
    line-height: 1.1;
    margin-bottom: 20px;
  }
  h4 {
    font-family: BasisGrotesque;
    font-size: 22px;
    font-weight: 500;
    font-style: italic;
    line-height: 1.36;
    margin-bottom: 12px;
  }
  p {
    font-family: 'Zilla Slab';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    margin-bottom: 20px;
    max-width: 640px;
  }
`;
