import React from 'react';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import { StyledReadyToJoin } from './StyledReadyToJoin';

export function ContributorRequirements() {
  return (
    <StyledContributorRequirements>
      <div>
        <h4>Requirements</h4>
        <p>
          CEB recruits Contributors by matching content needs with subject
          matter experts at its discretion. If you are interested in becoming a
          CEB Contributor, click the button below and fill out the form:
        </p>
      </div>
      <StyledReadyToJoinSidebar>
        <h2>Ready to Join the CEB Contributor Community?</h2>
        <a
          href="https://app.smartsheet.com/b/form/17586343b98b4b0d8687d0ad5848824c"
          target="_blank"
          rel="noopener noreferrer"
        >
          Become a CEB Contributor
        </a>
      </StyledReadyToJoinSidebar>
    </StyledContributorRequirements>
  );
}

const StyledContributorRequirements = styled.div`
  background: ${CEB_COLOR('NICE_BLUE')};
  border-radius: 10px;
  color: white;
  font-family: 'Zilla Slab', serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  margin-bottom: 50px;
  margin-top: 39px;
  max-width: 500px;
  overflow: hidden;
  > div {
    padding: 23px 36px 0;
  }
  h4 {
    font-family: LyonDisplay;
    font-size: 38px;
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    margin: 30px 0;
  }
  p {
    margin-bottom: 78px;
    font-size: 18px;

    line-height: 1.56;
  }
`;

const StyledReadyToJoinSidebar = styled(StyledReadyToJoin)`
  padding: 44px 36px 56px 36px;
  border-radius: 0;
  margin: 0;
  h2 {
    display: block;
    text-align: left;
    font-size: 34px;
    margin-bottom: 24px;
    line-height: 1.29;
    + a {
      margin: 0;
    }
  }
  a {
    display: block;
    margin: 0;
  }
`;
