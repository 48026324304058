import { userDefaults } from 'resolvers/user';

import { InMemoryCache } from '@apollo/client/cache';
import GET_LOCAL_USER from 'graphql/getLocalUser';

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        localUser: {
          merge: true,
        },
        search: {
          merge: true,
        },
        firm: {
          merge: true,
        },
        user: {
          merge: true,
        },
        bookmarks: {
          merge: false,
        },
      },
    },
  },
});

export const cacheReset = () => {
  cache.writeQuery({
    query: GET_LOCAL_USER,
    data: userDefaults,
  });
};
