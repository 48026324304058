import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

export const StyledReadyToJoin = styled.section`
  background: #f1f6ff;
  border-radius: 10px;
  border: 1px solid rgba(24, 92, 173, 0.11);
  margin-bottom: 48px;
  padding: 40px 0 40px 0;
  text-align: center;

  h2 {
    font-family: LyonDisplay;
    font-size: 38px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.04;
    display: inline-block;
    color: ${CEB_COLOR('CEB_HEADER_BLUE')};
  }
  a,
  a:visited {
    display: inline-block;
    background: ${CEB_COLOR('BLUE_RIBBON')};
    padding: 13px 53px 8px;
    border-radius: 5px;
    font-family: BasisGrotesque;
    font-size: 18px;
    font-weight: bold;
    color: white;
    vertical-align: 4px;
    text-decoration: none;
    + h2 {
      margin-left: 40px;
    }
  }
`;
