import { ExpandableSection } from 'components/common/expandable-section';
import styled from 'styled-components';

export const StyledExpandableSection = styled(ExpandableSection)`
  font-family: inherit;
  font-weight: inherit;
  .option {
    font-weight: normal;
  }
`;
