import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import includes from 'lodash/fp/includes';

function toErrorCodes(e) {
  return e.extensions.code;
}

const hasUnauthenticatedError = flow(
  map(toErrorCodes),
  includes('UNAUTHENTICATED'),
);

export default hasUnauthenticatedError;
