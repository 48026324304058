import 'babel-polyfill';
import 'element-qsa-scope';
import 'whatwg-fetch';
import React from 'react';
import { createRoot } from 'react-dom/client';
// eslint-disable-next-line no-unused-vars
import registerServiceWorker from './registerServiceWorker';
import '@fortawesome/fontawesome-free/css/all.css';
import { client } from './apollo/client';
import ContributorApp from './components/cle-contributor-app';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<ContributorApp client={client} />);
