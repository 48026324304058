import React from 'react';
import TitledPage from 'components/common/TitledPage';
import {
  ContributionSection,
  StyledContributionCard,
  StyledContributionSection,
} from './ContributionSection';
import { ContributorBenefits } from './ContributorBenefits';
import { StyledCCAMapBackground } from '..';
import { StyledReadyToJoin } from './StyledReadyToJoin';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import styled from 'styled-components';

function Join() {
  return (
    <div className="public-cle container px-3 mx-auto xl:px-0">
      <TitledPage title={'Join the Community'}>
        <StyledJoinPage>
          <ContributorBenefits />
          <ContributionSection />
          <StyledReadyToJoin>
            <a
              href="https://app.smartsheet.com/b/form/17586343b98b4b0d8687d0ad5848824c"
              target="_blank"
              rel="noopener noreferrer"
            >
              Become a CEB Contributor
            </a>
            <h2>…and join the Community today!</h2>
          </StyledReadyToJoin>
          <StyledContributionSection>
            <div>
              <StyledContributionCard>
                <h3>Already a CEB Contributor?</h3>
                <p>
                  For Contributors who have not yet filled out the Directory
                  Form and/or have questions about the CEB Contributor
                  Directory, please email{' '}
                  <a href="mailto:contributor@ceb.ucla.edu">
                    contributor@ceb.ucla.edu
                  </a>{' '}
                  for the Directory Form and answers to your questions.
                </p>
              </StyledContributionCard>
              <StyledContributionCard>
                <h3>Need to update your Profile?</h3>
                <p>
                  If you would like to update your profile at any time, please
                  use{' '}
                  <a
                    href="https://app.smartsheet.com/b/form/4f82d79d7670428c8334403bc04e0b5d"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    this form
                  </a>
                  . We want to hear about your latest achievements. Please allow
                  3-5 business days for updates to be reflected on your profile.
                </p>
              </StyledContributionCard>
            </div>
          </StyledContributionSection>
        </StyledJoinPage>
        <StyledCCAMapBackground />
      </TitledPage>
    </div>
  );
}

export default Join;

const StyledJoinPage = styled.section`
  max-width: 1166px;
  margin: 66px auto 96px;
  h3 {
    font-family: LyonDisplay;
    font-size: 36px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.8;
    margin-bottom: 8px;
    color: ${CEB_COLOR('CEB_HEADER_BLUE')};
  }
`;
