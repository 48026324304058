import React from 'react';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

export const ModalHeader = ({ close, children, className = undefined }) => {
  const handleClick = React.useCallback(
    e => {
      close && close(e);
    },
    [close],
  );

  return (
    <StyledModalHeader className={className}>
      <button className="close" onClick={handleClick}>
        <i className="fa fa-times" />
      </button>
      {children}
    </StyledModalHeader>
  );
};

const StyledModalHeader = styled.header`
  border-bottom: 2px solid ${CEB_COLOR('ATHENS_GRAY')};
  margin-bottom: 24px;
  > button.close {
    float: right;
    font-size: 20px;
    color: ${CEB_COLOR('BLACK')};
  }
`;
