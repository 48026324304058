export const basePaths = {
  cases: '/primary-law/cases',
  statutes: '/primary-law/statutes',
  courtRules: '/primary-law/rules',
  secondarySources: '/secondary-sources',
  practitioner: '/practitioner',
  news: '/news',
  courses: '/learning/courses',
  collections: '/learning/collections',
  learning: '/learning',
  docsandforms: '/documents_and_forms',
};

export const RESEARCH_SECTIONS = [
  {
    title: 'DailyNews',
    pathname: basePaths.news,
  },
  {
    title: 'Secondary Sources',
    pathname: basePaths.secondarySources,
  },
  {
    title: 'Cases',
    pathname: basePaths.cases,
  },
  {
    title: 'Statutes',
    pathname: basePaths.statutes,
  },
  {
    title: 'Rules of Court',
    pathname: basePaths.courtRules,
  },
  {
    title: 'Practitioner',
    pathname: basePaths.practitioner,
  },
  {
    title: 'Documents & Forms',
    pathname: basePaths.docsandforms,
  },
];

export const FLOW = {
  oauth_code: process.env.REACT_APP_OAUTH_CODE,
};

export const CEB_AUTH_TOKEN = 'auth-token';
export const CEB_IP_SESSION = 'ip-session';

export const CEB_ONLAW_URL = `${
  process.env.REACT_APP_ONLAW_URL
}/onlaw/gateway.dll?f=templates&fn=default.htm&vid=OnLAW:CEB`;
export const CEB_PORTAL_URL = process.env.REACT_APP_PORTAL_URL;
export const CEB_PURCHASE_FRAME_URL =
  process.env.REACT_APP_PORTAL_PURCHASE_FRAME_URL;
export const CEB_PORTAL_GA_ID = process.env.REACT_APP_PORTAL_GA_ID;
export const CEB_PORTAL_INTERCOM_APP_ID =
  process.env.REACT_APP_PORTAL_INTERCOM_APP_ID;
export const ENVIRONMENT_NAME = process.env.REACT_APP_ENVIRONMENT_NAME;
export const PUBLIC_SITE_URL = process.env.REACT_APP_PUBLIC_SITE_URL;
export const PURCHASE_API_KEY = process.env.REACT_APP_PURCHASE_API_KEY;
export const STORE_URL = process.env.REACT_APP_STORE_URL;
export const CONTENTFUL_PREVIEW =
  process.env.REACT_APP_CONTENTFUL_PREVIEW === 'true';
export const BUGSNAG_API_KEY = process.env.REACT_APP_BUGSNAG_API_KEY;
export const BUGSNAG_RELEASE_STAGE =
  process.env.REACT_APP_BUGSNAG_RELEASE_STAGE;
export const ALLOW_LOGGEDOUT_PREVIEW =
  process.env.REACT_APP_ALLOW_LOGGEDOUT_PREVIEW === 'true';
export const ENABLE_HISTORY_FEATURE =
  process.env.REACT_APP_ENABLE_HISTORY_FEATURE === 'true';
export const ENABLE_MANAGE_FIRM =
  process.env.REACT_APP_ENABLE_MANAGE_FIRM === 'true';
export const ENABLE_FIRM_PURCHASES_DISPLAY =
  process.env.REACT_APP_ENABLE_FIRM_PURCHASES_DISPLAY === 'true';
export const RECAPTCHA_SITEKEY = process.env.REACT_APP_RECAPTCHA_SITEKEY;
export const INVITE_WSS = process.env.REACT_APP_INVITE_WSS;
export const DOCSPRING_PDF_URL = process.env.REACT_APP_DOCSPRING_PDF_URL;
