import Loading from 'components/common/Loading';
import React, { useState } from 'react';
import styled from 'styled-components';

export function MemoryTabs({ defaultTab = 0, children }) {
  const [[tabSelected, tabContent], setTabSelected] = useState([
    defaultTab,
    children?.filter(Boolean)[defaultTab]?.props.content,
  ]);

  const childrenWithProps = React.Children.map(
    children.filter(Boolean),
    (child, index) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          onClick: () => {
            setTabSelected([index, child.props.content]);
          },
          selected: tabSelected === index,
        });
      }
      return child;
    },
  );

  return (
    <>
      <StyledMemoryTabs>
        <ul>{childrenWithProps}</ul>
      </StyledMemoryTabs>
      <Loading>{tabContent}</Loading>
    </>
  );
}

export function MemoryTab({ selected, onClick, children }) {
  return (
    <li className={selected ? 'selected' : ''}>
      <button onClick={onClick}>{children}</button>
    </li>
  );
}

const StyledMemoryTabs = styled.nav`
  ul {
    display: flex;
    gap: 32px;
    list-style: none;
    li {
      font-family: 'BasisGrotesque', sans-serif;
      font-size: 15px;
      font-weight: bold;
      position: relative;
      button {
        padding-bottom: 10px;
        color: #003475;
        span {
          font-weight: normal;
        }
        outline: none;
      }
      &.selected button {
        border-bottom: 3px solid #f5c023;
      }
    }
  }
`;
