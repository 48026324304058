import React from 'react';
import styled from 'styled-components';
import { StyleConstant } from 'util/getStyleConstant';
import CLEContributorSearchResult from './CLEContributorSearchResult';

const CLEContributorList = ({ contributors }) => (
  <StyledContributorList>
    {contributors.map(contributor => (
      <li key={`cle-contributor-result-${contributor.slug}`}>
        <CLEContributorSearchResult {...contributor} />
      </li>
    ))}
  </StyledContributorList>
);

const StyledContributorList = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  row-gap: 48px;
  @media screen and (max-width: ${StyleConstant.BREAKPOINT_TABLET}) {
    grid-template-columns: 1fr;
  }
`;

export default CLEContributorList;
