import { nodeListToArray } from 'util/nodeListToArray';

export function finder(ref) {
  return function (selector) {
    return ref ? nodeListToArray(ref.querySelectorAll(selector)) : [];
  };
}

export function findParents(node) {
  if (!node) return [];
  return (sel, context) => {
    const parents = [];
    let parent = node.parentElement;

    while (parent && (!context || !parent.matches(context))) {
      if ((sel && parent.matches(sel)) || !sel) {
        parents.push(parent);
      }

      parent = parent.parentElement;
    }
    return parents;
  };
}

export function findParent(node) {
  if (!node) return undefined;
  return (sel, context) => {
    const parents = findParents(node)(sel, context);
    if (parents.length > 0) return parents[0];
  };
}

export function findChildren(node) {
  if (!node) return [];
  return sel => {
    const children = [];
    let child = node.children[0];
    while (child) {
      if ((sel && child.matches && child.matches(sel)) || !sel) {
        children.push(child);
      }
      child = child.nextSibling;
    }
    return children;
  };
}

export function findSibling(node, direction = 'next') {
  const prop = `${direction}Sibling`;
  return sel => {
    const siblings = [];
    let sibling = node[prop];
    while (sibling) {
      if ((sel && sibling.matches && sibling.matches(sel)) || !sel) {
        siblings.push(sibling);
      }
      sibling = sibling[prop];
    }
    return siblings;
  };
}

export function findPrev(node) {
  return findSibling(node, 'previous');
}

export function findNext(node) {
  return findSibling(node, 'next');
}

export function findScrollableParent(node) {
  const parents = findParents(node)();
  const scrollableParent = parents.find(node => {
    return node.scrollHeight > node.clientHeight;
  });
  return scrollableParent;
}
