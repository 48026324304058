import React from 'react';

const Workflows = ({ stroke = '#343434', size = 22 }) => (
  <svg width={`${size}px`} height={`${size}px`} viewBox={'0 0 22 22'}>
    <g
      id="----Know-How"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Practitioner-Search-Results-Single-practice-area"
        transform="translate(-381.000000, -685.000000)"
        fill="#343434"
        fillRule="nonzero"
      >
        <g id="Group-7" transform="translate(380.000000, 684.000000)">
          <g id="icon-workflow">
            <path
              d="M10,13.5 L2,13.5 C1.72385763,13.5 1.5,13.7238576 1.5,14 L1.5,22 C1.5,22.2761424 1.72385763,22.5 2,22.5 L10,22.5 C10.2761424,22.5 10.5,22.2761424 10.5,22 L10.5,14 C10.5,13.7238576 10.2761424,13.5 10,13.5 Z M9.5,14.5 L9.5,21.5 L2.5,21.5 L2.5,14.5 L9.5,14.5 Z"
              id="Rectangle"
            />
            <path
              d="M6,1.5 C3.51471863,1.5 1.5,3.51471863 1.5,6 C1.5,8.48528137 3.51471863,10.5 6,10.5 C8.48528137,10.5 10.5,8.48528137 10.5,6 C10.5,3.51471863 8.48528137,1.5 6,1.5 Z M6,2.5 C7.93299662,2.5 9.5,4.06700338 9.5,6 C9.5,7.93299662 7.93299662,9.5 6,9.5 C4.06700338,9.5 2.5,7.93299662 2.5,6 C2.5,4.06700338 4.06700338,2.5 6,2.5 Z"
              id="Rectangle-Copy"
            />
            <path
              d="M18,13.5 C15.5147186,13.5 13.5,15.5147186 13.5,18 C13.5,20.4852814 15.5147186,22.5 18,22.5 C20.4852814,22.5 22.5,20.4852814 22.5,18 C22.5,15.5147186 20.4852814,13.5 18,13.5 Z M18,14.5 C19.9329966,14.5 21.5,16.0670034 21.5,18 C21.5,19.9329966 19.9329966,21.5 18,21.5 C16.0670034,21.5 14.5,19.9329966 14.5,18 C14.5,16.0670034 16.0670034,14.5 18,14.5 Z"
              id="Rectangle-Copy-3"
            />
            <path
              d="M21.0426407,2.54264069 L15.0426407,2.54264069 C14.7664983,2.54264069 14.5426407,2.76649831 14.5426407,3.04264069 L14.5426407,9.04264069 C14.5426407,9.31878306 14.7664983,9.54264069 15.0426407,9.54264069 L21.0426407,9.54264069 C21.3187831,9.54264069 21.5426407,9.31878306 21.5426407,9.04264069 L21.5426407,3.04264069 C21.5426407,2.76649831 21.3187831,2.54264069 21.0426407,2.54264069 Z M20.542,3.542 L20.542,8.542 L15.542,8.542 L15.542,3.542 L20.542,3.542 Z"
              id="Rectangle-Copy-2"
              transform="translate(18.042641, 6.042641) rotate(45.000000) translate(-18.042641, -6.042641) "
            />
            <path
              d="M6,10 C6.24545989,10 6.44960837,10.1768752 6.49194433,10.4101244 L6.5,10.5 L6.5,13.5 C6.5,13.7761424 6.27614237,14 6,14 C5.75454011,14 5.55039163,13.8231248 5.50805567,13.5898756 L5.5,13.5 L5.5,10.5 C5.5,10.2238576 5.72385763,10 6,10 Z"
              id="Line-3"
            />
            <path
              d="M18,10 C18.2454599,10 18.4496084,10.1768752 18.4919443,10.4101244 L18.5,10.5 L18.5,13.5 C18.5,13.7761424 18.2761424,14 18,14 C17.7545401,14 17.5503916,13.8231248 17.5080557,13.5898756 L17.5,13.5 L17.5,10.5 C17.5,10.2238576 17.7238576,10 18,10 Z"
              id="Line-3-Copy"
            />
            <path
              d="M13.5,5.5 C13.7761424,5.5 14,5.72385763 14,6 C14,6.24545989 13.8231248,6.44960837 13.5898756,6.49194433 L13.5,6.5 L10.5,6.5 C10.2238576,6.5 10,6.27614237 10,6 C10,5.75454011 10.1768752,5.55039163 10.4101244,5.50805567 L10.5,5.5 L13.5,5.5 Z"
              id="Line"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Workflows;
