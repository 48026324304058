import useSuspendableQuery from 'hooks/useSuspendableQuery';
import React from 'react';
import { GET_CONTENTFUL_PRACTICE_AREAS } from 'components/daily-news/graphql';

export function usePracticeAreaRootSlugs() {
  const {
    data: {
      contentfulPracticeArea: {
        practiceAreaSubtopicsCollection: { items },
      },
    },
  } = useSuspendableQuery(GET_CONTENTFUL_PRACTICE_AREAS);

  const rootSlugs = React.useMemo(() => items.map(item => item.slug), [items]);

  return rootSlugs;
}
