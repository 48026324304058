import flow from 'lodash/fp/flow';
import pickBy from 'lodash/fp/pickBy';
import set from 'lodash/fp/set';
import queryString from 'util/qs';
import { useNavigateWithState } from 'components/routes/RedirectWithState';
import { arrayToString } from 'util/arrayToString';
import getQueryString from 'util/getQueryString';

const enabledToString = flow(
  pickBy(v => v === true),
  Object.keys,
  arrayToString,
);

function updateQueryString(optionFilters, queryVariable) {
  return flow(
    set('page', undefined),
    set(queryVariable, enabledToString(optionFilters) || undefined),
    queryString.stringify,
  );
}

export default function useChangeOptionFilters(
  queryVariable = 'optionFilters',
) {
  const navigate = useNavigateWithState();
  return function changeOptionFilters(optionValues) {
    const query = updateQueryString(
      optionValues,
      queryVariable,
    )(getQueryString());
    navigate(`${window.location.pathname}?${query}`);
  };
}
