import { gql } from '@apollo/client';

export const GET_SPECIAL_REQUIREMENTS_CATEGORIES = gql`
  query getAllSpecializationRequirementsCategories {
    contentfulCourseCreditCategoryCollection(
      where: { name: "Special Requirements" }
    ) {
      items {
        name
        childCategoriesCollection {
          items {
            sys {
              id
            }
            name
          }
        }
      }
    }
  }
`;
