import React from 'react';

const Charts = ({ stroke = '#343434' }) => (
  <svg width="23px" height="21px" viewBox="0 0 23 21">
    <g
      id="----Know-How"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Know-How-Doc-Type-Icons"
        transform="translate(-3.000000, -151.000000)"
        stroke={stroke}
      >
        <g id="Group-28" transform="translate(1.000000, 0.000000)">
          <g id="icon-charts" transform="translate(3.000000, 151.000000)">
            <polyline id="Stroke-3521" points="21 0 8.5 15.5 3.5 10.5" />
            <polyline
              id="Stroke-3522"
              points="18 9.5 18 20.5 0 20.5 0 2.5 13 2.5"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Charts;
