import React from 'react';
import styled from 'styled-components';
import {
  ModalOverlay,
  ModalOverlayProps,
} from 'components/common/modal-overlay';
import { ModalHeader } from 'components/common/modal-overlay/ModalHeader';
import { StyledTrueCiteModalContent } from '../TrueCiteHelpModalButton';
import { CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';
import { useAssetAccess } from 'components/asset-access';

export const PRIMARY_LAW_FILTER_GUIDE: Record<string, string> = {
  Disposition: `<p>The outcome of an appellate decision, whether affirming, reversing, or vacating the lower court’s decision.</p>
<p>Use this filter to find cases analogous to your own. For example if your client is the plaintiff and is moving for summary judgment, you may want to find other cases where the plaintiff’s motion was granted and the outcome was affirmed by the appellate court.</p>
`,
  "Appellant's Trial Court Role": `<p>The role at trial of the party who is appealing the current lower court decision and seeking a different result from the appellate court.</p>
<p>Use this filter to find cases analogous to your own. For example if you want to find other cases where the plaintiff’s summary judgment motion was granted and the outcome was affirmed by the appellate court, select cases where the appellant had the trial court role of defendant or cross-defendant.</p>`,
};

export function PrimaryLawFilterGuideHelpButton() {
  const { hasCitatorAccess } = useAssetAccess();

  const [modalVisible, setModalVisible] = React.useState(false);
  const closeModal = React.useCallback(
    (e?: React.SyntheticEvent<HTMLElement>) => {
      e!.stopPropagation();
      setModalVisible(false);
    },
    [setModalVisible],
  );
  const openModal = React.useCallback(
    (e: React.SyntheticEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setModalVisible(true);
    },
    [setModalVisible],
  );
  if (!hasCitatorAccess) return null;
  return (
    <>
      <StyledFilterHelpButton onClick={openModal}>
        <i className="fas fa-question-circle" />
      </StyledFilterHelpButton>
      <ModalOverlay
        minHeight="518px"
        maxWidth="888px"
        contentHeight="auto"
        visible={modalVisible}
        closeModal={closeModal}
      >
        <PrimaryLawFilterModalContent closeModal={closeModal} />
      </ModalOverlay>
    </>
  );
}

const StyledFilterHelpButton = styled.a`
  color: ${CEB_COLOR_RGBA('BLACK', 0.3)} !important;
  margin-left: 0.5em;
`;

function PrimaryLawFilterModalContent({
  closeModal,
}: Pick<ModalOverlayProps, 'closeModal'>) {
  return (
    <StyledPrimaryLawGuideContent>
      <ModalHeader close={closeModal}>
        <h3>Guide to Primary Law Filters</h3>
      </ModalHeader>
      <div className="primary-law-guide">
        <dl>
          {Object.keys(PRIMARY_LAW_FILTER_GUIDE).map(key => {
            const keyClassName = key.replace(/\s/g, '-').toLowerCase();
            return (
              <React.Fragment key={keyClassName}>
                <dt>{key}</dt>
                <dd
                  dangerouslySetInnerHTML={{
                    __html: PRIMARY_LAW_FILTER_GUIDE[key],
                  }}
                />
              </React.Fragment>
            );
          })}
        </dl>
      </div>
    </StyledPrimaryLawGuideContent>
  );
}

const StyledPrimaryLawGuideContent = styled(StyledTrueCiteModalContent)`
  .primary-law-guide {
    dt {
      font-size: 18px;
      font-weight: bold;
      margin-top: 1.45em;
      margin-bottom: 0.75em;
    }
    dd {
      line-height: 1.65em;
      margin-bottom: 35px;
      margin-left: 1.6em;
      p {
        margin-bottom: 0.6em;
      }
    }
  }
`;
