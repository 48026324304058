import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { gql } from '@apollo/client';
import { Paginate } from 'components/common/paginate';
import { useSearchParams } from 'react-router-dom';
import useSuspendableQuery from 'hooks/useSuspendableQuery';
import qs from 'util/qs';
import { SearchResultsPageInfo } from 'components/common/search/SearchResultsPageInfo';
import TitledPage from 'components/common/TitledPage';
import CLEContributorList from './CLEContributorList';

const GET_CONTRIBUTOR_SEARCH = gql`
  query getContributorSearch(
    $query: String
    $page: Int
    $practiceAreas: [ID]
    $regions: [String]
    $pageSize: Int = 10
  ) {
    contributorSearch(
      query: $query
      pageSize: $pageSize
      pageNumber: $page
      filters: { regions: $regions, practiceAreaIds: $practiceAreas }
    ) {
      totalCount
      pageInfo {
        currentPage
        totalPages
      }
      edges {
        slug
        practiceAreas {
          contentfulId
          slug
          name
        }
        firstName
        lastName
        webDisplayName
        title
        judge
        bio
        headshotUrl
        currentAwarenessItemsCount
        knowHowDocumentItemsCount
        courseItemsCount
        publicationContributionBooks {
          name
        }
      }
    }
  }
`;

export default function CLEContributorSearch() {
  const [searchParams] = useSearchParams();

  const variables = useMemo(
    () => ({
      query: searchParams.get('query'),
      practiceAreas: searchParams.get('practiceAreas')
        ? searchParams.get('practiceAreas').split(',')
        : [],
      regions: searchParams.get('regions')
        ? searchParams.get('regions').split(',')
        : [],
      page: searchParams.get('page') ? parseInt(searchParams.get('page')) : 1,
      pageSize: 10,
    }),
    [searchParams],
  );

  const { data } = useSuspendableQuery(GET_CONTRIBUTOR_SEARCH, {
    variables,
  });

  const href = useCallback(
    page => {
      return `?${qs.stringify({
        ...(variables.query && { query: variables.query }),
        ...(variables.regions && { regions: variables.regions.join(',') }),
        practiceAreas: variables.practiceAreas.join(','),
        page,
      })}`;
    },
    [variables],
  );

  return (
    <TitledPage title="CEB Contributors">
      <StyledContributorSearch>
        {variables.query ||
        variables.practiceAreas.length > 0 ||
        variables.regions.length > 0 ? (
          <div className="no-gutter">
            <StyledSearchResultsPageInfo
              data={data}
              type="CONTRIBUTOR"
              searchKey="contributorSearch"
              perPage={10}
              resultTypeMessage="Contributor"
              color="#343434"
            />
          </div>
        ) : (
          <h3>All Contributors</h3>
        )}
        <CLEContributorList contributors={data?.contributorSearch?.edges} />
        <div className="pagination">
          <Paginate
            href={href}
            current={data?.contributorSearch?.pageInfo?.currentPage}
            pages={data?.contributorSearch?.pageInfo?.totalPages}
            range={variables.pageSize}
          />
        </div>
      </StyledContributorSearch>
    </TitledPage>
  );
}

const StyledSearchResultsPageInfo = styled(SearchResultsPageInfo)`
  padding-bottom: 18.5px;
  margin-bottom: 47.5px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
`;

const StyledContributorSearch = styled.div`
  margin: 0 60px 0 60px;
  > h3 {
    font-family: 'LyonDisplay';
    font-size: 36px;
    margin-bottom: 32px;
  }
  > hr {
    margin: 0;
    padding: 0;
  }
  > ul {
    margin-bottom: 48px;
  }
  div.pagination {
  }

  div.no-gutter {
    margin-left: -60px;
  }
`;
