import React from 'react';
import { courseSearchUrl } from 'util/courseUrl';
import qs from 'util/qs';

export function useCoursePracticeAreaPathBuilder(
  courseSearchBase = courseSearchUrl,
) {
  return React.useCallback(
    area => {
      const ignoreUserPracticeAreas = courseSearchBase !== '/';

      return `${courseSearchBase}?${qs.stringify({
        ...(ignoreUserPracticeAreas ? {} : { yourPracticeAreas: true }),
        practiceAreas: area.contentfulId || area.sys.id,
      })}`;
    },
    [courseSearchBase],
  );
}
