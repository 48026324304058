import React from 'react';
import { ContentfulContent } from 'components/common/ContentfulContent';
import { RelatedPosts } from 'components/posts/RelatedPosts';
import { ArticleCopyright } from 'components/posts/ArticleCopyright';
import { PostHeader } from 'components/posts/PostHeader';
import { PracticeAreaTags } from 'components/daily-news/PracticeAreaTags';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import { DailyNewsContext } from 'components/daily-news/DailyNewsProvider';

function PostPracticeAreas({ post }) {
  const { rootPracticeAreas = [] } = React.useContext(DailyNewsContext);
  const rootSlugs = rootPracticeAreas.map(r => r.slug);
  const practiceAreas = post.practiceAreasCollection?.items?.filter(area =>
    rootSlugs.includes(area.slug),
  );

  return (
    practiceAreas && (
      <StyledPostPracticeAreas>
        <h4>Practice Areas</h4>
        <PracticeAreaTags practiceAreas={practiceAreas} />
      </StyledPostPracticeAreas>
    )
  );
}

export const StyledPostPracticeAreas = styled.section`
  margin-top: 60px;
  h4 {
    color: ${CEB_COLOR('BLACK_TWO')};
    font-family: 'BasisGrotesque', sans-serif;
    font-size: 16px;
    font-weight: 900;
    margin-bottom: 19px;
    text-transform: uppercase;
  }
`;

export const PostContent = ({ post }) => {
  return (
    <div>
      <PostHeader post={post} />
      <StyledPostContent className="contentful">
        <ContentfulContent content={post.content} />
      </StyledPostContent>
      <ArticleCopyright />
      {post.relatedContentCollection.items.length ? (
        <RelatedPosts items={post.relatedContentCollection.items} />
      ) : null}
      <PostPracticeAreas post={post} />
    </div>
  );
};

export const StyledPostContent = styled.article`
  font-family: 'BasisGrotesque', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  > p:first-child::first-letter {
    float: left;
    font-size: 60px;
    line-height: 70px;
    margin-right: 8px;
    @media print {
      float: none;
      font-size: inherit;
      line-height: inherit;
      margin: 0;
    }
  }

  p {
    margin-bottom: 32px;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
  ul,
  ol {
    margin-left: 2em;
    margin-bottom: 2em;
    p {
      margin: 0;
      + p {
        margin-top: 2em;
      }
    }
  }

  blockquote {
    max-width: 85%;
    margin: 0 auto;
  }
`;
