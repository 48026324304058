import { gql } from '@apollo/client';

export const GET_PRACTICE_AREA_WITH_COURSE = gql`
  query getAllPracticeAreasWithCourse {
    contentfulPracticeArea(id: "2IJw5gDFab7ZY5e7dNpLyL") {
      name
      practiceAreaSubtopicsCollection {
        items {
          sys {
            id
          }
          name
          slug
          linkedFrom {
            courseCollection {
              total
            }
          }
        }
      }
    }
  }
`;
