import { ApolloClient } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import { ApolloLink } from '@apollo/client/link/core';
import { withToken } from './withToken';
import { cache, cacheReset } from './cache';
import erase from 'util/cookie/erase';
import { CEB_AUTH_TOKEN } from 'app-constants';
import { User } from 'resolvers/user';
import typeDefs from 'graphql/clientSchema';
import hasUnauthenticatedError from './hasUnauthenticatedError';

const uri = `${process.env.REACT_APP_GRAPHQL}/graphql`;
const batchHttpLink = new BatchHttpLink({
  uri,
  headers: { batch: 'true' },
  credentials: 'include',
});

export const client = new ApolloClient({
  cache: cache.restore(window.__APOLLO_STATE__),
  link: ApolloLink.from([
    withToken,
    onError(({ graphQLErrors, networkError }) => {
      if (
        hasUnauthenticatedError(graphQLErrors) ||
        (networkError && networkError.statusCode === 401)
      ) {
        erase(CEB_AUTH_TOKEN);
        client.clearStore();
        window.location.reload();
      }
    }),
    batchHttpLink,
  ]),
  resolvers: User,
  typeDefs,
});

cacheReset();

client.onResetStore(() => {
  cacheReset();
});
