import React from 'react';
import StatusMessage from 'components/common/StatusMessage';
import { bugsnagClient } from 'util/bugsnagClient';

const GQL_ERROR_MAP = {
  'cannot parse: Add a closing quote': 'Try adding a closing quote.',
};

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    bugsnagClient && bugsnagClient.notify(error);
    console.error(error, info);
  }

  onRefresh(e) {
    e.preventDefault();
    window.location.reload();
  }

  render() {
    return this.state.hasError ? (
      <StatusMessage icon="fa-exclamation-triangle">
        {this.props.message ? (
          this.props.message
        ) : (
          <>
            There was a problem while loading.{' '}
            <a href="#" onClick={this.onRefresh}>
              Click here to try reloading the page.
            </a>{' '}
            If the problem persists please contact technical support for
            assistance.
            {this.state.error?.graphQLErrors?.length &&
              this.state.error?.graphQLErrors?.map(e =>
                GQL_ERROR_MAP[e.extensions.originalErrorMessage] ? (
                  <>
                    <br />
                    <br />
                    {GQL_ERROR_MAP[e.extensions.originalErrorMessage]}
                  </>
                ) : null,
              )}
          </>
        )}
      </StatusMessage>
    ) : (
      this.props.children
    );
  }
}
