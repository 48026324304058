import { gql } from '@apollo/client';

const typeDefs = gql`
  extend type User {
    authorized: Boolean
    token: String
    returnUrl: String
    postLoginRedirect: String
  }

  extend type Mutation {
    updateLocalUser(
      authorized: Boolean
      token: String
      returnUrl: String
      postLoginRedirect: String
    ): User
  }
`;

export default typeDefs;
