import { gql } from '@apollo/client';
import React from 'react';
import { useContributionQuery } from './useContributionQuery';
import styled from 'styled-components';
import { ElementRenderer } from 'components/common/element-renderer';
import { stringify } from 'himalaya';
import { useContentDocument } from 'components/practitioner/document/useContentDocument';
import { orderBy } from 'lodash/fp';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import { IconFromType } from 'components/practitioner/IconFromType';
import { practitionerUrl } from 'util/practitionerUrl';
import { usePracticeAreaRootSlugs } from 'components/cle-library/usePracticeAreaRootSlugs';

const GET_SECONDARY_SOURCES_CONTRIBUTIONS = gql`
  query getSecondarySourcesContributions($slug: String!) {
    contentfulContributorCollection(where: { slug: $slug }, limit: 1) {
      items {
        linkedFrom {
          knowHowDocumentCollection(limit: 100) {
            items {
              slug
              title
              originalPublishDate
              practitionerDocumentType {
                name
              }
              practiceAreasCollection(limit: 10) {
                items {
                  sys {
                    id
                  }
                  name
                  slug
                }
              }
              processedContent
            }
          }
        }
      }
    }
  }
`;

const byPublishedDate = orderBy(['originalPublishDate'])('desc');

export function PractitionerDocumentContributions({ slug }) {
  const items = useContributionQuery(
    GET_SECONDARY_SOURCES_CONTRIBUTIONS,
    slug,
    'knowHowDocumentCollection',
  );
  const rootSlugs = usePracticeAreaRootSlugs();

  return (
    <ul>
      {byPublishedDate(items).map((item, i) => (
        <ContributionResult
          item={item}
          rootSlugs={rootSlugs}
          key={`knowHowDocumentCollection-${i}`}
        />
      ))}
    </ul>
  );
}

function useExcerpt(processedContent) {
  const { contentEl } = useContentDocument(stringify(processedContent));
  return contentEl.querySelector('root > p, root > section > p');
}

function ContributionResult({ item, rootSlugs, ...props }) {
  const excerpt = useExcerpt(item?.processedContent);
  if (!item) return null;
  const practiceAreas = item.practiceAreasCollection.items.filter(area =>
    rootSlugs.includes(area.slug),
  );
  const path = practitionerUrl(item.practitionerDocumentType.name, item.slug);

  return (
    <StyledContributionResult>
      <span className="icon">
        <IconFromType type={item.practitionerDocumentType.name} />
      </span>
      <h3>
        <a href={`https://research.ceb.com${path}`}>{item.title}</a>
      </h3>
      <StyledPracticeAreas>
        {practiceAreas?.map((practiceArea, i) => (
          <li key={`${practiceArea.slug}-${i}`}>
            <a href={`/?practiceAreas=${practiceArea.sys.id}`}>
              {practiceArea.name}
            </a>
          </li>
        ))}
      </StyledPracticeAreas>
      <ElementRenderer content={excerpt} className="excerpt" />
    </StyledContributionResult>
  );
}

const StyledPracticeAreas = styled.ul`
  margin-bottom: 8px;
  li {
    display: inline-block;
    margin-right: 0.5em;
    font-family: 'Zilla Slab', serif;
    font-size: 15px;
    font-weight: bold;
  }
  a {
    color: inherit;
  }
  li + li {
    &:before {
      content: '•';
      margin-right: 0.5em;
    }
  }
`;

const StyledContributionResult = styled.li`
  padding-left: 35px;
  margin-bottom: 36px;
  &:first-child {
    margin-top: 48px;
  }
  span.icon {
    float: left;
    margin-left: -35px;
    display: inline;
  }
  h3 {
    font-family: 'Zilla Slab', serif;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 12px;
    a {
      color: ${CEB_COLOR('CEB_HEADER_BLUE')};
    }
  }
  .excerpt {
    font-family: 'Zilla Slab', serif;
    font-size: 16px;
    line-height: 1.5;
  }
`;
