import { gql } from '@apollo/client';
import Firm from 'components/common/icons/Firm';
import TitledPage from 'components/common/TitledPage';
import useSuspendableQuery from 'hooks/useSuspendableQuery';
import React, { useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { CEBContributorsHeader } from './CEBContributorsHeader';
import CLEContributorList from './CLEContributorList';

const GET_CONTRIBUTOR_ORGANIZATION = gql`
  query getContributorOrganization($slug: String!) {
    contributorOrganization(slug: $slug) {
      name
      slug
      website
      contributors {
        slug
        practiceAreas {
          contentfulId
          slug
          name
        }
        firstName
        lastName
        webDisplayName
        title
        judge
        bio
        headshotUrl
        currentAwarenessItemsCount
        knowHowDocumentItemsCount
        courseItemsCount
      }
    }
  }
`;

export default function CLEContributorOrganization() {
  const location = useLocation();
  const { slug } = useParams();
  const { data } = useSuspendableQuery(GET_CONTRIBUTOR_ORGANIZATION, {
    variables: { slug },
  });
  const navigate = useNavigate();
  const onClickBack = useCallback(() => {
    navigate(location.state?.fromTitle ? -1 : '/');
  }, [navigate, location]);

  return (
    <div className="public-cle container px-3 mx-auto xl:px-0">
      <TitledPage
        title={`${data.contributorOrganization.name} - CEB Contributors`}
      >
        <CEBContributorsHeader className="alternative" />
        <StyledCLEContent>
          <button className="back-link" onClick={onClickBack}>
            ←{' '}
            {location.state?.fromTitle
              ? location.state.fromTitle
              : 'Back to All Contributors'}
          </button>

          <StyledCLEOrganizationHeader>
            <Firm width={48} height={48} />
            <div>
              <h2>{data.contributorOrganization.name}</h2>
              <a href={data.contributorOrganization.website}>
                {data.contributorOrganization.website}{' '}
                <i className="fas fa-external-link-alt" />
              </a>
            </div>
          </StyledCLEOrganizationHeader>

          <CLEContributorList
            contributors={data.contributorOrganization.contributors}
          />
        </StyledCLEContent>
      </TitledPage>
    </div>
  );
}

const StyledCLEContent = styled.section`
  padding: 0 158px;
  padding-bottom: 96px;

  .back-link {
    font-family: 'BasisGrotesque', sans-serif;
    font-size: 17px;
    font-weight: bold;
    color: #185cad;
    line-height: 1.88;
  }
`;

const StyledCLEOrganizationHeader = styled.div`
  display: flex;
  margin-top: 12px;
  margin-bottom: 60px;
  > svg {
    margin-right: 18px;
  }
  > div {
    > h2 {
      font-family: 'LyonDisplay', serif;
      font-size: 48px;
      line-height: 1.13;
    }
    > a {
      font-family: 'Zilla Slab', serif;
      font-size: 16px;

      > i {
        font-size: 14px;
        margin-left: 5px;
      }
    }
  }
`;
