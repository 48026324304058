import { basePaths } from 'app-constants';
import lowerCase from 'lodash/fp/lowerCase';
import replace from 'lodash/fp/replace';
import flow from 'lodash/fp/flow';

const dasherize = flow(
  lowerCase,
  replace(/\s/g, '-'),
);

export const practitionerUrl = (doctype, slug) => {
  if (doctype === 'Workflows') {
    return `${basePaths.practitioner}/workflow/${slug}`;
  } else {
    return `${basePaths.practitioner}/${dasherize(doctype)}/${slug}`;
  }
};

export const workflowUrl = (
  { practiceArea, practiceAreaSubtopic, practiceAreaSubcategory, ...params },
  slug,
) => {
  return `${
    basePaths.practitioner
  }/area/${practiceArea}/${practiceAreaSubtopic}/${
    practiceAreaSubcategory ? `${practiceAreaSubcategory}/` : ''
  }workflow/${slug}`;
};
