const RENAME_ATTRIBUTE_MAP = {
  class: 'className',
  ref: 'data-ref',
  cellpadding: 'cellPadding',
  cellspacing: 'cellSpacing',
  rowspan: 'rowSpan',
  colspan: 'colSpan',
};

const transformValue = {
  style: attr => {
    const template = document.createElement('template');
    template.setAttribute('style', attr.value);
    return Object.entries(template.style)
      .filter(([key]) => !/^[0-9]+$/.test(key))
      .filter(([, value]) => Boolean(value))
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  },
};

export function getReactAttributes(attributes) {
  const reactAttributes = {};

  Array.from(attributes).forEach(attr => {
    const name = RENAME_ATTRIBUTE_MAP[attr.name]
      ? RENAME_ATTRIBUTE_MAP[attr.name]
      : attr.name;

    const value = transformValue[name]
      ? transformValue[name](attr)
      : attr.value;

    reactAttributes[name] = value;
  });

  return reactAttributes;
}
