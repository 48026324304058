import React from 'react';
import { TrackedLink as Link } from 'components/common/TrackedLink';
import styled from 'styled-components';
import { CEB_COLOR, CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';

export function PracticeAreaTags({
  practiceAreas,
  pathBuilder = area => `/news/${area.slug}`,
}) {
  return (
    <StyledTags>
      {practiceAreas.map((area, i) => {
        const url = pathBuilder(area);
        return (
          <li key={area.contentfulId || area.sys?.id || `${area.slug}-${i}`}>
            {url.startsWith('/') ? (
              <StyledTag as={Link} to={url}>
                {area.name}
              </StyledTag>
            ) : (
              <StyledTag href={url}>{area.name}</StyledTag>
            )}
          </li>
        );
      })}
    </StyledTags>
  );
}

export const StyledTags = styled.ul.attrs(
  ({ bg = 'SLATE_GREY', fg = 'GUNMETAL' }) => ({ bg, fg }),
)`
  list-style-type: none;
  margin-top: 12px;
  li {
    margin: 0;
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 8px;
  }
`;

export const StyledTag = styled.a.attrs(
  ({ bg = 'SLATE_GREY', fg = 'GUNMETAL' }) => ({ bg, fg }),
)`
  &,
  &:visited {
    background: ${({ bg }) => CEB_COLOR_RGBA(bg, 0.1)};
    border-radius: 3px;
    color: ${({ fg }) => CEB_COLOR(fg)};
    display: inline-block;
    font-family: Lato;
    font-size: 12px;
    font-weight: 900;
    padding: 4px 10px;
    @media print {
      border: 1px solid ${({ bg }) => CEB_COLOR_RGBA(bg, 0.1)};
      background: none;
    }
  }
`;
