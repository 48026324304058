import get from 'util/cookie/get';
import set from 'util/cookie/set';
import erase from 'util/cookie/erase';
import { CEB_AUTH_TOKEN, CEB_IP_SESSION } from 'app-constants';
import GET_LOCAL_USER from 'graphql/getLocalUser';

const token = get(CEB_AUTH_TOKEN);
const ipSession = get(CEB_IP_SESSION);

export const userDefaults = {
  localUser: {
    __typename: 'LocalUser',
    authorized: token !== null,
    ipSession: ipSession !== null,
    token,
    returnUrl: '',
    postLoginRedirect: false,
  },
};

export const User = {
  Mutation: {
    updateLocalUser: (_, { token, ipSession, ...attributes }, { cache }) => {
      const data = {
        localUser: {
          __typename: 'LocalUser',
          token,
          ipSession,
          ...attributes,
        },
      };

      if (token) {
        set(CEB_AUTH_TOKEN, token);
        if (ipSession) {
          set(CEB_IP_SESSION, 'true');
        }
      } else {
        erase(CEB_AUTH_TOKEN);
        erase(CEB_IP_SESSION);
      }
      cache.writeQuery({ query: GET_LOCAL_USER, data });
      return data.localUser;
    },
  },
};
