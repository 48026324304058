import React from 'react';
import { ApolloProvider } from '@apollo/client';
import styled, { createGlobalStyle } from 'styled-components';
import Loading from 'components/common/Loading';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ScrollToTop from 'components/app/ScrollToTop';
import { IntercomProvider } from 'react-use-intercom';
import { CEB_PORTAL_GA_ID, CEB_PORTAL_INTERCOM_APP_ID } from 'app-constants';
import CLEContributorLanding from './CLEContributorLanding';
import { AppContext } from 'components/app-context';
import { useIntercomDefaultLauncher } from 'components/layouts/useIntercomWidget';
import TagManager from 'react-gtm-module';
import CLEContributorProfile from './cl-contributor-profile';
import CLEContributorOrganization from './CLEContributorOrganization';
import Join from './join';
import { StyleConstant } from 'util/getStyleConstant';
import { StyledGlobalApp } from 'components/app/StyledGlobalApp';

function AppContextProvider({ children }) {
  useIntercomDefaultLauncher();

  if (CEB_PORTAL_GA_ID) {
    TagManager.initialize({
      gtmId: CEB_PORTAL_GA_ID,
    });
  }

  return <AppContext.Provider value={{}}>{children}</AppContext.Provider>;
}

export default function CLEContributorApp({ client }) {
  return (
    <IntercomProvider
      shouldInitialize={CEB_PORTAL_INTERCOM_APP_ID !== undefined}
      appId={CEB_PORTAL_INTERCOM_APP_ID}
      autoBoot={CEB_PORTAL_INTERCOM_APP_ID !== undefined}
      autoBootProps={{ hideDefaultLauncher: true }}
    >
      <HelmetProvider>
        <ApolloProvider client={client}>
          <Loading>
            <Router>
              <AppContextProvider>
                <ScrollToTop />
                <Loading>
                  <Routes>
                    <Route path="/" element={<CLEContributorLanding />} />
                    <Route path="/join" element={<Join />} />
                    <Route
                      path="/contributor/:slug"
                      element={<CLEContributorProfile />}
                    />
                    <Route
                      path="/organization/:slug"
                      element={<CLEContributorOrganization />}
                    />
                  </Routes>
                </Loading>
              </AppContextProvider>
            </Router>
          </Loading>
        </ApolloProvider>
      </HelmetProvider>
      <StyledGlobalApp />
      <GlobalStyleOverwrites />
    </IntercomProvider>
  );
}

export const StyledCCAMapBackground = createGlobalStyle`
  body {
    background-color: white;
    background-image: url('/images/contributor/ca-map.svg');
    background-position: top right;
    background-repeat: no-repeat;
    .bg-white{
      background: transparent;
    }
  }
`;

const StyledContainer = styled.div``;

const GlobalStyleOverwrites = createGlobalStyle`
  html, body {
    height: auto !important;
    width: auto !important;
    font-family: "BasisGrotesque", sans-serif !important;

    a:visited{
      color: inherit;
    }

    @media screen and (min-width: ${StyleConstant.BREAKPOINT_HANDHELD}) {
      height: auto !important;
      width: auto !important;
    }
  }



  #root{
    width: auto !important;
    height: auto !important;
    padding-top: 100px;
    overflow: visible;
    .container{
      margin: 0 auto;
    }
  }

  .site-footer {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .min-h-screen{
    display: flex;
    flex-direction: column;
    > *:first-child{
      flex: 0 1 auto;
    }
    > * {
      flex: 1 1 auto;
    }
    > *last-child{
      flex: 0 1 auto;
    }
  }
  .site-menu__submenu-wrap,
  .site-menu__submenu-content {
    background: white;
  }

  .site-menu__button--primary {
    &:visited{
      color: white;
    }
  }
`;
