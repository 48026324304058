import React from 'react';

const Strategy = ({ stroke = '#343434' }) => (
  <svg width="24px" height="22px" viewBox="0 0 24 22">
    <g
      id="----Know-How"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Know-How-Doc-Type-Icons"
        transform="translate(0.000000, -40.000000)"
        stroke={stroke}
      >
        <g id="Group-28" transform="translate(1.000000, 0.000000)">
          <g id="icon-strategy-copy" transform="translate(0.000000, 40.000000)">
            <path
              d="M1.2,0.7 L6.8,6.3"
              id="Line"
              transform="translate(4.000000, 3.500000) scale(-1, 1) translate(-4.000000, -3.500000) "
            />
            <path d="M1.2,0.7 L6.8,6.3" id="Line" />
            <path
              d="M15.2,14.7 L20.8,20.3"
              id="Line"
              transform="translate(18.000000, 17.500000) scale(-1, 1) translate(-18.000000, -17.500000) "
            />
            <path d="M15.2,14.7 L20.8,20.3" id="Line" />
            <circle id="Oval" cx="3" cy="18.5" r="3" />
            <polyline id="Path-2" points="3 15.5 3 10.5 19 10.5 19 0.5" />
            <polyline id="Path-3" points="22 4.5 19 0.5 16 4.5" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Strategy;
