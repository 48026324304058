import React from 'react';
import Charts from 'components/common/icons/know-how/charts';
import HowTo from 'components/common/icons/know-how/howto';
import Insights from 'components/common/icons/know-how/insights';
import Strategy from 'components/common/icons/know-how/strategy';
import Templates from 'components/common/icons/know-how/templates';
import Workflows from 'components/common/icons/know-how/workflows';

export const IconFromType = ({ type, ...props }) => {
  switch (type) {
    // How-To Guides
    case 'How-To Guides':
      return <HowTo {...props} />;
    // Charts & Checklists
    case 'Charts & Checklists':
      return <Charts {...props} />;
    // Standard Documents
    case 'Standard Documents':
      return <Templates {...props} />;
    // Strategy Notes
    case 'Strategy Notes':
      return <Strategy {...props} />;
    // Expert Insights
    case 'Expert Insights':
      return <Insights {...props} />;
    // Workflows
    case 'Workflows':
      return <Workflows {...props} />;
    default:
      return null;
  }
};
