import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import React from 'react';
// import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

export const CEBContributorsHeader = ({ className }) => (
  <StyledPageHeader className={className}>
    <h2>CEB Contributors</h2>
    {/* <Link to="#">Become a Contributor</Link> */}
  </StyledPageHeader>
);

const StyledPageHeader = styled.header`
  display: flex;
  align-items: center;
  margin: 0 60px 40px;
  h2 {
    font-family: 'LyonDisplay', serif;
    font-size: 54px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    flex: 1;
    line-height: 1.3;
    letter-spacing: normal;
    margin-left: 24px;
    white-space: nowrap;
  }

  a,
  a:visited {
    background: white;
    border-radius: 5px;
    border: 2px solid ${CEB_COLOR('BLUE_RIBBON')};
    box-sizing: border-box;
    color: ${CEB_COLOR('BLUE_RIBBON')};
    display: inline-block;
    font-family: 'BasisGrotesque', sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.02em;
    padding: 9px 14px 5px;
    text-align: center;
    text-decoration: none;
  }

  &.alternative {
    border-bottom: solid 1px #e1e1e1;
    padding-bottom: 63.6px;
    margin-top: 36px;

    > h2 {
      margin-left: 0;
    }
  }
`;
