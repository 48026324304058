import styled from 'styled-components';
import { CEB_COLOR, CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';

export default styled.div.attrs(({ checkboxGutter = 25 }) => ({
  checkboxGutter,
}))`
  &.option {
    padding-left: 25px;
    &:last-child {
      padding-bottom: 25px;
    }
    .option {
      &:last-child {
        padding-bottom: 0;
      }
      padding-left: 12px;
    }
    &.disabled,
    .disabled & {
      opacity: 0.5;
      label,
      input {
        cursor: default;
      }
    }
    &.hide {
      display: none;
    }
  }

  label {
    display: block;
    font-size: 0.85em;
    padding: 4px 25px 4px ${({ checkboxGutter }) => checkboxGutter}px;
    position: relative;
    cursor: pointer;
    .level-indicator {
      margin: 3px 0;
      max-width: 70%;
      height: 10px;
    }
    input {
      appearance: none;
      background: white;
      border-radius: 2px;
      border: 1px solid ${CEB_COLOR('ALTO')};
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1) inset;
      cursor: pointer;
      height: 16px;
      margin: 0 10px 0 0;
      margin-left: -${({ checkboxGutter }) => checkboxGutter}px;
      position: absolute;
      width: 16px;
      &:after {
        -webkit-font-smoothing: antialiased;
        color: ${CEB_COLOR('NICE_BLUE')};
        display: inline-block;
        font-family: 'Font Awesome 5 Free';
        font-size: 10px;
        font-style: normal;
        font-variant: normal;
        font-weight: 900;
        text-rendering: auto;
      }

      &:indeterminate {
        &:after {
          content: '\f068';
          position: absolute;
          left: 3px;
          top: 2px;
        }
      }
      &:checked {
        &:after {
          content: '\f00c';
          position: absolute;
          left: 2px;
          top: 2px;
        }
      }
    }
  }

  .total {
    float: right;
    font-weight: bold;
    color: ${CEB_COLOR_RGBA('BLACK', 0.6)};
    margin-left: 1em;
  }
`;
