import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';
import React from 'react';
import styled from 'styled-components';
import { truncateToNearestWord } from 'util/truncateToNearestWord';
import pluralize from 'util/pluralize';
import { PracticeAreaTags } from 'components/daily-news/PracticeAreaTags';
import { useCoursePracticeAreaPathBuilder } from 'components/cle-library/landing/useCoursePracticeAreaPathBuilder';
import { NavLink } from 'react-router-dom';

const truncate = truncateToNearestWord(150, ' …');

const ContributorMedia = ({ count, title }) =>
  count > 0 ? (
    <li>
      <strong>{count}</strong> {pluralize(count, title)}
    </li>
  ) : null;

export default function CLEContributorSearchResult({
  headshotUrl,
  webDisplayName,
  title,
  bio,
  courseItemsCount,
  currentAwarenessItemsCount,
  knowHowDocumentItemsCount,
  publicationContributionBooks,
  practiceAreas,
  slug,
}) {
  const practiceAreaPathBuilder = useCoursePracticeAreaPathBuilder('/');
  return (
    <StyledContributorSearchResult>
      <NavLink to={`/contributor/${slug}`}>
        <img src={headshotUrl} alt={webDisplayName} />
        <h4>{webDisplayName}</h4>
      </NavLink>
      <h5>{title}</h5>
      <p>{truncate(documentToPlainTextString(bio))}</p>
      <ul className="contributor-media">
        <ContributorMedia count={currentAwarenessItemsCount} title="Article" />
        <ContributorMedia
          count={publicationContributionBooks?.length}
          title="Secondary Source"
        />
        <ContributorMedia
          count={knowHowDocumentItemsCount}
          title="Practitioner Document"
        />
        <ContributorMedia count={courseItemsCount} title="Program" />
      </ul>
      <PracticeAreaTags
        practiceAreas={practiceAreas}
        pathBuilder={practiceAreaPathBuilder}
      />
    </StyledContributorSearchResult>
  );
}

const StyledContributorSearchResult = styled.div`
  img {
    border-radius: 40px;
    width: 72px;
    height: 72px;
    margin-bottom: 12px;
  }
  h4 {
    color: ${CEB_COLOR('CEB_HEADER_BLUE')};
    font-family: 'LyonDisplay', serif;
    font-size: 24px;
    line-height: 1.25;
    margin-bottom: 2px;
  }
  h5 {
    font-family: 'BasisGrotesque', sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    line-height: 2.14;
    margin-bottom: 1px;
  }
  p {
    font-family: 'Zilla Slab', serif;
    font-size: 15px;
    line-height: 1.33;
    margin-bottom: 2px;
  }
  > ul.contributor-media {
    li {
      display: inline;
      font-family: 'Zilla Slab', serif;
      font-size: 15px;
      line-height: 2;
      margin-bottom: 8px;
      &:before {
        content: ', ';
      }

      &:first-child {
        &:before {
          content: '';
        }
      }
    }
  }
`;
