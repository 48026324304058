import React from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';

/**
 * QueryInput utility component
 */

type ValidatorFunction = (
  value: string,
  event: React.ChangeEvent<HTMLInputElement>,
) => boolean;
type Validator = ValidatorFunction | RegExp;

function validate(
  value: string,
  validator: Validator | undefined,
  event: React.ChangeEvent<HTMLInputElement>,
): boolean {
  if (validator) {
    if (typeof validator === 'function') {
      return validator(value, event);
    } else {
      return validator.test(value);
    }
  } else {
    return !!value;
  }
}

export interface QueryInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  mutate?: (value: any) => void;
  through?: string | React.ComponentType<any>;
  invert?: boolean;
  validator?: (
    value: any,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => boolean;
  navigateOptions?: Record<string, any>;
}

export const QueryInput = ({
  mutate,
  through = 'input',
  invert,
  validator,
  navigateOptions = {},
  ...props
}: QueryInputProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const prop = React.useMemo(
    () =>
      ['checkbox', 'radio'].includes(props.type || '') ? 'checked' : 'value',
    [props.type],
  );
  const [value, setValue] = React.useState<string | undefined>(
    searchParams.get(props.name || '') || '',
  );
  const queryStringValue = React.useMemo(
    () => searchParams.get(props.name || '') || '',
    [searchParams, props.name],
  );

  const onChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value =
        props.type === 'checkbox'
          ? event.target.checked
            ? event.target.value
            : undefined
          : props.type === 'radio'
            ? event.target.value === ''
              ? undefined
              : event.target.value
            : event.target.value;

      if (validator && !validate(value!, validator, event)) {
        setValue(value);
      } else {
        if (value) {
          if (invert) searchParams.delete(props.name!);
          else searchParams.set(props.name!, value);
        } else if (invert) searchParams.set(props.name!, props.value as string);
        else searchParams.delete(props.name!);

        setSearchParams(searchParams, navigateOptions);
      }
    },
    [searchParams, invert, validator, props, setSearchParams],
  );

  React.useEffect(() => setValue(queryStringValue), [queryStringValue]);

  return React.createElement(through, {
    ...props,
    onChange,
    [prop]:
      props.type === 'radio'
        ? value === props.value
        : invert
          ? value
            ? ''
            : props.value
          : value,
  });
};

QueryInput.propTypes = {
  /** react element name or custom component to render with props */
  through: PropTypes.any,
  /** mutation function for mutating querystring after update */
  mutate: PropTypes.func,
};
