import React from 'react';

const Templates = ({ stroke = '#343434' }) => (
  <svg width="20px" height="25px" viewBox="0 0 20 25">
    <g
      id="----Know-How"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Know-How-Doc-Type-Icons"
        transform="translate(-3.000000, -116.000000)"
        stroke={stroke}
      >
        <g id="Group-28" transform="translate(1.000000, 0.000000)">
          <g id="icon-templates" transform="translate(3.000000, 117.000000)">
            <polyline
              id="Stroke-3382"
              points="13.5 3 18 3 18 23 0 23 0 3 4.5 3"
            />
            <path
              d="M11,2 C11,0.8955 10.105,0 9,0 C7.895,0 7,0.8955 7,2 L5,2 L5,6 L13,6 L13,2 L11,2 L11,2 Z"
              id="Stroke-3383"
            />
            <polyline
              id="Stroke-3384"
              points="4.33333333 5 2 5 2 19 16 19 16 5 13.6666667 5"
            />
            <path d="M4.5,9 L13.5,9" id="Stroke-3386" />
            <path d="M4.5,12 L13.5,12" id="Stroke-3387" />
            <path d="M4.5,15 L7.5,15" id="Stroke-3388" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Templates;
