export function truncateToNearestWordFunc(
  str = '',
  limit = 150,
  trailer = ' …',
) {
  return (
    str
      .slice(0, limit)
      .split(' ')
      .slice(0, -1)
      .join(' ') + trailer
  );
}

export function truncateToNearestWord(limit = 150, trailer = ' …') {
  return str => truncateToNearestWordFunc(str, limit, trailer);
}
