import React from 'react';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import useQueryString from 'hooks/useQueryString';

function useUpdateSearch() {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateSearch = React.useCallback(
    query => {
      searchParams.delete('page');
      if (query) searchParams.set('query', query);
      else searchParams.delete('query');

      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  return updateSearch;
}

export function CLELibrarySearchInput({ placeholder = 'Search Library' }) {
  const updateSearch = useUpdateSearch();
  const keyPress = React.useCallback(
    e => {
      if (e.key === 'Enter' || e.target.value.length === 0) {
        updateSearch(e.target.value);
      }
    },
    [updateSearch],
  );
  const { query } = useQueryString();

  return (
    <StyledSearchInput>
      <i className="fas fa-search" />

      <input
        placeholder={placeholder}
        aria-owns="typeahead-results"
        autoComplete="off"
        name="query"
        type="text"
        onKeyUp={keyPress}
        defaultValue={query}
      />
    </StyledSearchInput>
  );
}

const StyledSearchInput = styled.div`
  border-bottom: solid 2px #dededb;
  display: flex;
  height: 56px;
  i {
    font-size: 14px;
    margin-left: 24px;
    margin-right: 11px;
    line-height: 55px;
    color: #343434;
  }
  input {
    background: none;
    font-family: 'BasisGrotesque', sans-serif;
    color: rgba(52, 52, 52, 0.8);
    flex: 1;
    &:focus {
      outline: 0;
    }
    &::placeholder {
      color: rgba(52, 52, 52, 0.8);
    }
  }
`;
