import React from 'react';
const circle = 'var(--COLOR_DANUBE)';
const path = 'var(--COLOR_LUCKY_POINT)';

const Firm = ({ height = 64, width = 64, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 64 64" {...props}>
    <circle fill={props.circle} cx={32} cy={32} r={32} />
    <path
      d="M32,58 C46.3594035,58 58,46.3594035 58,32 C58,17.6405965 46.3594035,6 32,6 C17.6405965,6 6,17.6405965 6,32 C6,46.3594035 17.6405965,58 32,58 Z M32,54 C19.8497355,54 10,44.1502645 10,32 C10,19.8497355 19.8497355,10 32,10 C44.1502645,10 54,19.8497355 54,32 C54,44.1502645 44.1502645,54 32,54 Z"
      id="Oval"
      fill={props.path}
      fillRule="nonzero"
    />
    <g
      id="Group-6"
      transform="translate(12.000000, 20.000000)"
      fill={props.path}
    >
      <g id="Group-3" transform="translate(4.000000, 0.000000)">
        <polygon id="Line" fillRule="nonzero" points="4 16 4 34 10 34 10 16" />
        <polygon
          id="Line-Copy"
          fillRule="nonzero"
          points="13 16 13 34 19 34 19 16"
        />
        <polygon
          id="Line-Copy-2"
          fillRule="nonzero"
          points="22 16 22 34 28 34 28 16"
        />
        <rect id="Rectangle-Copy-2" x="10" y="10" width="12" height="4" />
        <rect id="Rectangle-Copy-3" x="0" y="0" width="32" height="2" />
      </g>
      <path
        d="M8,10 C8,10.8954305 6.8954305,12 6,12 C5.1045695,12 4,10.8954305 4,10 C4,9.1045695 5.1045695,8 6,8 L34,8 C34.8954305,8 36,9.1045695 36,10 C36,10.8954305 34.8954305,12 34,12 C33.1045695,12 32,10.8954305 32,10 L28,10 C28,13.1045695 30.8954305,16 34,16 C37.1045695,16 40,13.1045695 40,10 C40,6.8954305 37.1045695,4 34,4 L6,4 C2.8954305,4 0,6.8954305 0,10 C0,13.1045695 2.8954305,16 6,16 C9.1045695,16 12,13.1045695 12,10 L8,10 Z"
        id="Path-2"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

Firm.defaultProps = {
  circle,
  path,
};

export default Firm;
