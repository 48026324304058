import React, { useEffect } from 'react';
import { hasProductCode, ProductCodeFilter } from 'util/hasProductCode';
import { useProductCollectionSlugs } from './useProductCollectionSlugs';
import { GetAssetsDocument, GetAssetsQuery } from '__generated__/graphql';
import { useSuspenseQuery } from '@apollo/client';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { CEBProductFlags, useProductFlags } from './useProductFlags';

export interface AssetAccessState extends CEBProductFlags {
  assets?: GetAssetsQuery['assets'];
  availableProductSlugs?: string[] | null;
  setAssets?: (assets: GetAssetsQuery['assets'] | null) => void;
  hasAccess?: (productCode: ProductCodeFilter) => boolean;
}

export const assetAccessAtom = atom<AssetAccessState>({
  key: 'AssetAccessState',
  default: {},
});

export interface AssetAccessProps {
  children: React.ReactNode;
}

export default function AssetAccess(props: AssetAccessProps) {
  const [overrideAsssets, setAssets] = React.useState<
    GetAssetsQuery['assets'] | null
  >(null);

  const { data: assetsData } = useSuspenseQuery(GetAssetsDocument, {
    fetchPolicy: 'network-only',
  });

  const availableProductSlugs = useProductCollectionSlugs(
    assetsData?.assets!,
    /^13/,
  );

  const assets = React.useMemo(
    () => overrideAsssets || assetsData?.assets,
    [overrideAsssets, assetsData],
  );

  const productFlags = useProductFlags(assets!);

  const setAssetAccess = useSetRecoilState(assetAccessAtom);

  const hasAccess = React.useCallback(
    (productCode: ProductCodeFilter) => {
      return productCode ? hasProductCode(assets!, productCode, false) : false;
    },
    [assets],
  );

  useEffect(() => {
    setAssetAccess({
      ...productFlags,
      assets,
      availableProductSlugs,
      setAssets,
      hasAccess,
    });
  }, [setAssetAccess, assets, productFlags, availableProductSlugs, hasAccess]);

  return props.children;
}

export function useAssetAccess() {
  return useRecoilValue(assetAccessAtom);
}
