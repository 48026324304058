import useQueryString from 'hooks/useQueryString';
import React from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const location = useLocation();
  const { page } = useQueryString();

  React.useLayoutEffect(() => window.scrollTo(0, 0), [location.pathname, page]);
  return null;
}

export default ScrollToTop;
