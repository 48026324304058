import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

interface TimeProps {
  time: string;
  format?: string;
  parseFormat?: string;
}

export interface RelativeTimeProps extends TimeProps {
  humanizedThreshold?: [number, dayjs.QUnitType];
  alwaysHumanize?: boolean;
}

export interface FormattedTimeProps extends TimeProps {
  utc?: boolean;
  icon?: string;
  flex?: boolean;
}

export const FormattedTime = ({
  time,
  format = 'MMMM DD, YYYY',
  parseFormat,
  utc = true,
  icon,
  flex,
}: FormattedTimeProps) => {
  const convertedDayjs = utc
    ? dayjs.utc(time, parseFormat)
    : dayjs(time, parseFormat);
  return (
    <StyledFormattedTime dateTime={time} flex={flex} title={time}>
      {icon ? <i className={`far fa-${icon}`} /> : null}
      {convertedDayjs.format(format)}
    </StyledFormattedTime>
  );
};

interface StyledFormattedTimeProps {
  flex?: boolean;
}

const StyledFormattedTime = styled.time<StyledFormattedTimeProps>`
  i {
    margin-right: 0.5em;
    font-size: 1.1em;
  }
  time {
    ${({ flex }) => (flex ? 'flex: 0 1 auto;' : '')}
    white-space: nowrap;
  }
`;

export const RelativeTime = ({
  time,
  parseFormat,
  humanizedThreshold = [1, 'month'],
  format = 'MMMM DD, YYYY',
  alwaysHumanize = false,
}: RelativeTimeProps) => {
  const shouldHumanize = React.useMemo(() => {
    return (
      alwaysHumanize ||
      dayjs().diff(dayjs(time), humanizedThreshold[1]) < humanizedThreshold[0]
    );
  }, [time, humanizedThreshold, alwaysHumanize]);

  const readableTime = React.useMemo(() => {
    return shouldHumanize
      ? dayjs().to(dayjs(time, parseFormat))
      : dayjs(time, parseFormat).format(format);
  }, [time, format, shouldHumanize]);

  return (
    <StyledFormattedTime dateTime={time} title={time}>
      {readableTime}
    </StyledFormattedTime>
  );
};
