import { createGlobalStyle } from 'styled-components';
import { StyleConstant } from 'util/getStyleConstant';
import { CEB_COLOR, CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';

export const StyledGlobalApp = createGlobalStyle`
  :root {
    --FONT_SERIF: 'Zilla Slab', serif;
    --FONT_SANS_SERIF: 'BasisGrotesque', sans-serif;
    --FONT_SANS_SERIF_ALT: font-family: 'Bitter', serif;

    /* grays */
    --COLOR_BLACK_ZERO: #000000;
    --COLOR_BLACK: #343434;
    --COLOR_BLACK_TWO: #111111;
    --COLOR_BLACK_THREE: #545454;
    --COLOR_COD_GRAY: #171717;
    --COLOR_RICE_CAKE: #fffdef;
    --COLOR_ALTO: #dedede;
    --COLOR_ATHENS_GRAY: #eeeef4;
    --COLOR_CARARRA: #f0eeeb;
    --COLOR_DESERT_STORM: #fafaf9;
    --COLOR_DOVE_GRAY: #6a6a6a;
    --COLOR_SLATE_GREY: #5a5969;
    --COLOR_GUNMETAL: #676968;
  
    --COLOR_GALLERY: #eaeaea;
    --COLOR_GALLERY_DARK: #aeaeae;
    --COLOR_QUILL_GRAY: #e4e4e2;
    --COLOR_MERCURY: #e2e2e2;
    --COLOR_BLUEY_GRAY: #99a2b8;
    --COLOR_SILVER: #ccc;
    --COLOR_WILD_SAND: #f6f6f6;
    --COLOR_WESTAR: #dddbd9;
    --COLOR_VERY_LIGHT_PINK: #f8f7f5;
  
    --COLOR_HOVER_BASE: #766742;
    --COLOR_HOVER: rgba(118, 103, 66, 0.05);
  
    /* reds */
    --COLOR_TUSCANY: #ba4c2b;
    --COLOR_BRICK_RED: #8e1b05;
    --COLOR_REDDY_BROWN: #751604;
    --COLOR_WARNING_RED: #d33a4d;
    --COLOR_RUSSET: #984406;
  
    /* blues */
    --COLOR_DANUBE: #5698ca;
    --COLOR_NICE_BLUE: #185cad;
    --COLOR_HAVELOCK_BLUE: #4d92e3;
    --COLOR_LUCKY_POINT: #1f226a;
    --COLOR_COOL_BLUE: #4099cf;
    --COLOR_STEEL_BLUE: #518dc1;
    --COLOR_JELLY_BEAN: #2774ae;
    --COLOR_CEB_HEADER_BLUE: #003475;
    --COLOR_NIGHT_BLUE: #070a4e;
    --COLOR_BOTTICELLI: #c7d5e0;
    --COLOR_PALE_GREY: #f6fbff;
    --COLOR_DARK_GREY_BLUE: #29364f;
    --COLOR_POLO_BLUE: #8aacd5;
    --COLOR_LIGHT_BLUE: #cfe3e6;
  
    /* blue/green */
    --COLOR_DUSTY_TEAL: #448f78;
    --COLOR_SEA_FOAM: #52a7b9;
    --COLOR_ICE: #f1fff6;
    --COLOR_TEAL: #007f80;
    --COLOR_BLUE_RIBBON: #006df4;
    --COLOR_BLUE_STONE: #005e5f;
    --COLOR_BOTTLE_GREEN: #0C3C37;
  
    /* yellows */
    --COLOR_BUTTERED_RUM: #aa7e11;
    --COLOR_DI_SERRIA: #d99b4e;
    --COLOR_RAW_SIENNA: #cf9240;
    --COLOR_CITRINE_WHITE: #f7f7ca;
    --COLOR_LIGHTNING_YELLOW: #fdc028;
    --COLOR_SAFFRON: #f5c023;
    --COLOR_ORANGEY_YELLOW: #fdba12;
    --COLOR_GOLD: #ffd100;
    --COLOR_CORN: #e2b900;
  
    /* attitude colors */
    --ATTITUDE_COLORS_CAUTION: rgba(234, 161, 21, 1);
    --ATTITUDE_COLORS_CONTRAST: rgba(234, 161, 21, 1);
    --ATTITUDE_COLORS_CITED: rgba(86, 152, 202, 1);
    --ATTITUDE_COLORS_DISCUSSED: rgba(86, 152, 202, 1);
    --ATTITUDE_COLORS_NEGATIVE: rgba(186, 76, 43, 1);
    --ATTITUDE_COLORS_UNKNOWN: rgba(90, 89, 105, 1);
    --ATTITUDE_COLORS_WARNING: rgba(234, 132, 95, 1);
  
    /* breakpoints */
    --BREAKPOINT_SMALL_HANDHELD: 320px;
    --BREAKPOINT_HANDHELD: 960px;
    --BREAKPOINT_TABLET: 1240px;
  
    /* selection background */
    --SELECTION_BG: rgba(207, 227, 230, 0.99);
  }

  * {
    background: transparent;
    color: inherit;
    margin: 0;
    padding: 0;
    :not(table, td, th){
      border: 0;
    }
    &:focus {
      outline-color: ${CEB_COLOR_RGBA('DANUBE', 0.6)};
      outline-style: solid;
      outline-width: 1px;
    }
  }

  body {
    background-color: rgb(248, 247, 245);
    font-family: 'Zilla Slab', serif;
    color: ${CEB_COLOR('BLACK')};
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100vh;
    @media only print {
      background-color: white;
    }
  }

  html,
  body,
  #root {
    @media screen and (min-width: ${StyleConstant.BREAKPOINT_HANDHELD}) {
      height: 100vh;
      overflow: auto;
      width: 100vw;
    }
  }
  
  ::-moz-selection{
    background: ${CEB_COLOR_RGBA('LIGHT_BLUE', 0.99)};
  }

  ::selection {
    background: ${CEB_COLOR_RGBA('LIGHT_BLUE', 0.99)};
  }

  input[type='checkbox'] {
    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px ${CEB_COLOR_RGBA('DANUBE', 0.6)};
    }
  }

  button, label {
    cursor: pointer;
    &[disabled]{
      cursor: default;
    }
  }

  a,
  a:visited {
    color: ${CEB_COLOR('NICE_BLUE')};
    text-decoration: none;
    &:focus {
      border-bottom: 1px solid ${CEB_COLOR_RGBA('DANUBE', 0.6)};
      margin-bottom: -1px;
      outline: 0;
    }
  }
`;
