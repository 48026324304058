import React from 'react';
import { FilterOption } from 'components/practitioner/FilterOption';
import { YourPracticeAreas } from './YourPracticeAreas';
import { useFilters } from './useFilters';

import { StyledExpandableSection } from './StyledExpandableSection';

export function CLELibraryPracticeAreaFilters({
  query,
  linkedFrom,
  userPracticeAreas,
}) {
  const [
    practiceAreaFilters,
    practiceAreas,
    onPracticeAreaOptionChange,
  ] = useFilters(
    query,
    'practiceAreas',
    'contentfulPracticeArea.practiceAreaSubtopicsCollection.items',
  );

  const userPracticeAreaIds = React.useMemo(
    () => (userPracticeAreas ? userPracticeAreas.map(area => area.sys.id) : []),
    [userPracticeAreas],
  );

  return (
    <StyledExpandableSection
      name="Practice Area"
      border={false}
      defaultExpanded
      cache
    >
      {userPracticeAreas && (
        <YourPracticeAreas
          userPracticeAreas={userPracticeAreas}
          practiceAreas={practiceAreas}
        />
      )}
      {practiceAreaFilters
        .filter(
          item =>
            item.linkedFrom[linkedFrom]?.total > 0 &&
            !userPracticeAreaIds.includes(item.sys.id),
        )
        .map(item => (
          <FilterOption
            key={item.sys.id}
            filter={{
              filterValue: item.sys.id,
              name: item.name,
              checked: practiceAreas[item.sys.id] === true,
            }}
            onOptionChange={onPracticeAreaOptionChange}
          />
        ))}
    </StyledExpandableSection>
  );
}
