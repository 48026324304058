import React from 'react';
import { ENABLE_HISTORY_FEATURE } from 'app-constants';
import { AppContext } from 'components/app-context';
import { BookmarkContext } from 'components/app-context/BookmarkContextProvider';
import styled from 'styled-components';
import { CEB_COLOR_RGBA } from 'app-constants/CEB_COLOR';

export function PostTools({ post }) {
  const { localUser } = React.useContext(AppContext) || {};
  const { bookmarkToggle } = React.useContext(BookmarkContext) || {};

  const showHistoryAndBookmarks =
    ENABLE_HISTORY_FEATURE && !localUser.ipSession;

  return (
    <StyledPostTools>
      <button className="print" onClick={window.print}>
        <i className="fa fa-print" />
      </button>
      {showHistoryAndBookmarks && (
        <button className="bookmark" onClick={bookmarkToggle}>
          <i className="fa fa-bookmark" />
        </button>
      )}
    </StyledPostTools>
  );
}

const StyledPostTools = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  button {
    font-size: 18px;
    + button {
      margin-left: 21px;
    }
    &.bookmark {
      color: ${CEB_COLOR_RGBA('BLACK', 0.26)};
    }
  }
  @media print {
    display: none;
  }
`;
