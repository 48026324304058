import useSuspendableQuery from 'hooks/useSuspendableQuery';

export function useContributionQuery(query, slug, dataKey) {
  const { data } = useSuspendableQuery(query, {
    variables: { slug },
  });

  const items =
    data.contentfulContributorCollection.items[0].linkedFrom[dataKey].items;

  return items;
}
